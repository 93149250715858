function BaseObject(options) {
  const self = this;

  /**
   * @prop {object}
   */
  this.defaultOptions = {};

  /**
   * @prop {object}
   */
  this.options = _.isObject(options) ? options : {};

  /**
   * Initialize object.
   *
   * @return {BaseObject}
   */
  this.init = function() {
    return this
      .initDataAttributes()
      .initEarlyProps()
      .initDefaults()
      .initOptions()
      .initProps()
      .processOptions();
  };

  /**
   * Initialize data attributes.
   *
   * @return {BaseObject}
   */
  this.initDataAttributes = function() {
    this.extendOptions({
      data: {},
    });

    _.each(this.options.data, function(value, key) {
      if (null === this[key]) {
        this[key] = value;
      } else if (null === this[`_${key}`]) {
        this[`_${key}`] = value;
      }
    }, this);

    return this.processDataAttributes();
  };

  /**
   * Process data attributes.
   *
   * @return {BaseObject}
   */
  this.processDataAttributes = function() {
    return this;
  };

  /**
   * Initialize early properties (called after "initDataAttributes" and before
   * "initDefaults").
   *
   * @return {BaseObject}
   */
  this.initEarlyProps = function() {
    return this;
  };

  /**
   * Initialize defaults.
   *
   * @return {BaseObject}
   */
  this.initDefaults = function() {
    return this;
  };

  /**
   * Initialize options.
   *
   * @return {BaseObject}
   */
  this.initOptions = function() {
    this.options = this.defaultOptions.extend(this.options);
    return this;
  };

  /**
   * Initialize properties.
   *
   * @return {BaseObject}
   */
  this.initProps = function() {
    return this;
  };

  /**
   * Process options.
   *
   * @return {BaseObject}
   */
  this.processOptions = function() {
    return this;
  };

  /**
   * Extend default options.
   *
   * @param  {object}     newDefaultOptions
   * @return {BaseObject}
   */
  this.extendDefaultOptions = function(newDefaultOptions) {
    this.defaultOptions = this.defaultOptions.extend(newDefaultOptions);
    return this;
  };

  /**
   * Extend options.
   *
   * @param  {object}     newOptions
   * @return {BaseObject}
   */
  this.extendOptions = function(newOptions) {
    this.options = this.options.extend(newOptions);
    return this;
  };

  /**
   * Set data attributes.
   *
   * @param  {object}     dataAttributes
   * @return {BaseObject}
   */
  this.setDataAttributes = function(dataAttributes) {
    _.each(dataAttributes, (value, key) => {
      self.setDataAttribute(key, value);
    });

    return this;
  };

  /**
   * Set data attribute.
   *
   * @param  {string}     key
   * @param  {*}          value
   * @return {BaseObject}
   */
  this.setDataAttribute = function(key, value) {
    this[key] = value;
    return this;
  };

  /**
   * Get option.
   *
   * @param  {string} key
   * @return {*}
   */
  this.getOption = function(key) {
    return this.options[key];
  };

  /**
   * Set option.
   *
   * @param  {string}     key
   * @param  {*}          value
   * @return {BaseObject}
   */
  this.setOption = function(key, value) {
    this.options[key] = value;
    return this;
  };

  /**
   * Get options.
   *
   * @return {object}
   */
  this.getOptions = function() {
    return this.options;
  };

  /**
   * Remove value from array option.
   *
   * @param  {string}     key
   * @param  {*}          value
   * @return {BaseObject}
   */
  this.removeFromArrayOption = function(key, valueToRemove) {
    const arrayOption = this.getOption(key);

    if (!_.isArray(arrayOption)) {
      return this;
    }

    return this.setOption(
      key,
      arrayOption.filter((value) => valueToRemove !== value),
    );
  };
}

export default BaseObject;
