import BaseComponent from 'js/base_v2/component';
import Renderer from 'js/components/renderer';

/**
 * Base Field.
 *
 * @class
 * @abstract
 * @extends BaseComponent
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     [options]
 */
function BaseField(fieldCt, fieldEl, options) {
  BaseComponent.call(this, options);
  const parent = this.clone();

  /**
   * @prop {DOMElement}
   */
  this.fieldCt = fieldCt;

  /**
   * @prop {DOMElement}
   */
  this.fieldEl = fieldEl;

  /**
   * @prop {DOMElement}
   */
  this.actionSpan = null;

  /**
   * @prop {DOMElement}
   */
  this.messageSpan = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      value: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    this
      .initFields()
      .registerEventListeners();

    return this;
  };

  /**
   * Initialize fields.
   *
   * @return {BaseField}
   */
  this.initFields = function() {
    if (_.isString(this.fieldCt)) {
      this.fieldCt = $(this.fieldCt);
    }

    if (_.isString(this.fieldEl)) {
      this.fieldEl = $(this.fieldEl, this.fieldCt);
    }

    if (!_.isUndefined(this.options.value)) {
      this.setValue(this.options.value);
    }

    this.actionSpan = $('.actionSpan', this.fieldCt);
    this.messageSpan = $('.messageSpan', this.fieldCt);

    return this;
  };

  /**
   * Register event listeners.
   *
   * @return {BaseField}
   */
  this.registerEventListeners = function() {
    return this;
  };

  /**
   * Set message.
   *
   * @param  {string}              html
   * @return {ContactProjectField}
   */
  this.setMessage = function(html) {
    this.messageSpan.html(html);
    return this;
  };

  /**
   * Get field container.
   *
   * @return {DOMElement}
   */
  this.getContainer = function() {
    return this.fieldCt;
  };

  /**
   * Get field DOM element.
   *
   * @return {DOMElement}
   */
  this.getFieldEl = function() {
    return this.fieldEl;
  };

  /**
   * Get field value.
   *
   * @return {string}
   */
  this.getValue = function() {
    return this.fieldEl.val();
  };

  /**
   * Set field value.
   *
   * @param  {string}    value
   * @return {BaseField}
   */
  this.setValue = function(value) {
    this.fieldEl.val(value);
    return this;
  };

  /**
   * Switch to {actionType} view.
   *
   * @param {string} actionType ['select', 'createInline']
   */
  this.switchTo = function(actionType) {
    switch (actionType) {
      case 'select':
        this.switchToSelect();
        break;
      case 'createInline':
        this.switchToCreateInline();
        break;
      default:
        // Do nothing
    }
  };

  /**
   * Switch to "select" view.
   */
  this.switchToSelect = function() {
    const html = $(`#${this.options.selectTmplId}`)
      .tmpl(this.options.selectTmplParams);

    this.fieldCt.html(html);
  };

  /**
   * Switch to "create inline" view.
   */
  this.switchToCreateInline = function() {
    const html = $(`#${this.options.createInlineTmplId}`)
      .tmpl(this.options.createInlineTmplParams);

    this.fieldCt.html(html);
  };

  /**
   * Notify error.
   *
   * @param  {string}    error
   * @return {BaseField}
   */
  this.notifyError = function(error) {
    this.messageSpan.html(Renderer.Error(error));
    return this;
  };

  /**
   * Notify loading.
   *
   * @return {BaseField}
   */
  this.notifyLoading = function() {
    this.messageSpan.html(Renderer.Loading());
    return this;
  };

  /**
   * Clear notifications.
   *
   * @return {BaseField}
   */
  this.clearNotifications = function() {
    this.messageSpan.html('');
    return this;
  };
}

export default BaseField;
