import BaseField from 'js/base_v2/field';

const staticSelf = BaseSelect2Field;

/**
 * @const
 */
staticSelf.VALUE_INITIALIZATION_BEFORE_SELECT2 = 'before_select2';

/**
 * @const
 */
staticSelf.VALUE_INITIALIZATION_AFTER_SELECT2 = 'after_select2';

/**
 * Base Select2 Field.
 *
 * @class
 * @abstract
 * @extends BaseField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     [options]
 */
function BaseSelect2Field(fieldCt, fieldEl, options) {
  BaseField.call(this, fieldCt, fieldEl, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {DOMElement}
   */
  this.selectEl = fieldEl;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      initialValue: undefined,
      valueInitialization: staticSelf.VALUE_INITIALIZATION_BEFORE_SELECT2,
      search: false,
      createNew: false,
      createNewOptions: {
        attributes: {},
        triggerClick: true,
        newTagLabel: undefined,
      },
      openOnFocus: true,
      onOpening: undefined,
      onSelecting: undefined,
      onUnselecting: undefined,
      onChange: undefined,
      onClose: undefined,
      select2: {
        placeholder: '',
        createTag(params) {
          return self.onCreateTag(params);
        },
        allowClear: true,
        width: 'element',
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    if (!this.options.search) {
      this.options.select2.minimumResultsForSearch = -1;
    }

    if (true === this.options.createNew) {
      this.options.select2.tags = true;
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    parent.create.call(this);

    // eslint-disable-next-line max-len
    if (this.options.valueInitialization === staticSelf.VALUE_INITIALIZATION_AFTER_SELECT2) {
      this.setInitialValue();
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initFields = function() {
    parent.initFields.call(this);

    // eslint-disable-next-line max-len
    if (this.options.valueInitialization === staticSelf.VALUE_INITIALIZATION_BEFORE_SELECT2) {
      this.setInitialValue();
    }

    this.fieldEl.select2(this.options.select2);

    return this;
  };

  /**
   * Set field initial value.
   *
   * @return {BaseSelect2Field}
   */
  this.setInitialValue = function() {
    const { initialValue } = this.options;

    if (_.isUndefined(initialValue) || _.isNull(initialValue)) {
      return this;
    }

    this.setValue(initialValue);

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    this.fieldCt.on(
      'focus',
      '.select2-selection.select2-selection--single',
      () => {
        self.onFocus();
      },
    );

    this.fieldEl.on('select2:opening', (event) => {
      self.onOpening(event);
    });

    this.fieldEl.on('select2:open', (event) => {
      self.onOpen(event);
    });

    this.fieldEl.on('select2:selecting', (event) => {
      self.onSelecting(event);
    });

    this.fieldEl.on('select2:select', (event) => {
      self.onSelect(event);
    });

    this.fieldEl.on('select2:unselecting', (event) => {
      self.onUnselecting(event);
    });

    this.fieldEl.on('select2:unselect', (event) => {
      self.onUnselect(event);
    });

    this.fieldEl.on('select2:closing', () => {
      self.onClosing();
    });

    this.fieldEl.on('select2:close', (event) => {
      self.onClose(event);
    });

    this.fieldEl.on('select2:clear', (event) => {
      this.onClear(event);
    });

    this.fieldEl.on('change', (event) => {
      self.onChange(event);
    });

    return this;
  };

  /**
   * Field focus event handler.
   */
  this.onFocus = function() {
    if (this.options.openOnFocus && this.fieldEl.is(':enabled')) {
      this.open();
    }
  };

  /**
   * Opening (before open) event handler.
   *
   * @param  {object}            event
   * @return {boolean|undefined}
   */
  this.onOpening = function(event) {
    if (_.isFunction(this.options.onOpening) &&
      false === this.options.onOpening(event)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Open event handler.
   *
   * @param {object} event
   */
  // eslint-disable-next-line no-unused-vars
  this.onOpen = function(event) {
    $('.select2-container--open .select2-search__field')[0].focus();
  };

  /**
   * Selecting (before select) event handler.
   *
   * @param  {object}            event
   * @return {boolean|undefined}
   */
  this.onSelecting = function(event) {
    if (_.isFunction(this.options.onSelecting) &&
      false === this.options.onSelecting(event)
    ) {
      return false;
    }

    if (true === this.options.createNew) {
      const { data } = event.params.args;

      if (data.newTag && this.options.createNewOptions.triggerClick) {
        $('.createNewTag').trigger('click');
      }
    }

    return undefined;
  };

  /**
   * Select event handler.
   *
   * @param {object} event
   */
  // eslint-disable-next-line no-unused-vars
  this.onSelect = function(event) {};

  /**
   * Unselecting (before unselect) event handler.
   *
   * @param  {object}            event
   * @return {boolean|undefined}
   */
  this.onUnselecting = function(event) {
    if (_.isFunction(this.options.onUnselecting) &&
      false === this.options.onUnselecting(event)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Unselect event handler.
   *
   * @param {object} event
   */
  // eslint-disable-next-line no-unused-vars
  this.onUnselect = function(event) {};

  /**
   * Closing (before dropdown is closed) event handler.
   */
  this.onClosing = function() {
    // Steal focus during close - only capture once and stop propogation
    this.fieldEl.data('select2').$selection.one('focus focusin', (event) => {
      event.stopPropagation();
    });
  };

  /**
   * Close (dropdown is closed) event handler.
   *
   * @param  {object}            event
   * @return {boolean|undefined}
   */
  this.onClose = function(event) {
    if (_.isFunction(this.options.onClose) &&
      false === this.options.onClose(event)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Clear selection event handler.
   *
   * @param {object} event
   */
  // eslint-disable-next-line no-unused-vars
  this.onClear = function(event) {};

  /**
   * Change event handler.
   *
   * @param  {object}            event
   * @return {boolean|undefined}
   */
  this.onChange = function(event) {
    if (_.isFunction(this.options.onChange) &&
      false === this.options.onChange(event)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Create tag event handler.
   *
   * @param {object} params
   */
  this.onCreateTag = function(params) {
    const term = $.trim(params.term);

    if ('' === term) {
      return null;
    }

    if (true === this.options.createNew) {
      return this.createNewTag(term);
    }

    return {
      id: term,
      text: term,
      newTag: true,
    };
  };

  /**
   * Set value.
   *
   * @param  {string}           value
   * @param  {boolean}          [triggerChange]
   * @return {BaseSelect2Field}
   */
  this.setValue = function(value, triggerChange) {
    // eslint-disable-next-line no-param-reassign
    triggerChange = _.isUndefined(triggerChange) ? true : triggerChange;

    this.fieldEl.val(value);

    if (triggerChange) {
      this.fieldEl.trigger('change');
    }

    return this;
  };

  /**
   * Open dropdown.
   *
   * @return {BaseSelect2Field}
   */
  this.open = function() {
    if (this.fieldEl.data('select2').isOpen()) {
      // Dropdown is already opened
      return this;
    }

    this.fieldEl.select2('open');

    return this;
  };

  /**
   * Create new tag.
   *
   * @param  {string} term
   * @return {object}
   */
  this.createNewTag = function(term) {
    return {
      id: this.getNewTagValue(term),
      text: this.getNewTagLabel(term),
      newTag: true,
    };
  };

  /**
   * Get new tag value.
   *
   * @param  {string} term
   * @return {string}
   */
  this.getNewTagValue = function(term) {
    return `__tmp__${term}`;
  };

  /**
   * Get new tag label.
   *
   * @param  {string} term
   * @return {string}
   */
  this.getNewTagLabel = function(term) {
    const a = $('<a href="#">');

    _.each(this.options.createNewOptions.attributes, (value, key) => {
      a.attr(key, value);
    }, this);

    const label = _.isString(this.options.createNewOptions.newTagLabel) ?
      this.options.createNewOptions.newTagLabel :
      term;

    a
      .addClass('createNewTag')
      .attr('data-term', term)
      .html(`<i class="fa fa-plus" aria-hidden="true"></i> ${label}`);

    return a[0].outerHTML;
  };

  /**
   * Set field disabled.
   *
   * @prop   {boolean}          disabled
   * @return {BaseSelect2Field}
   */
  this.setDisabled = function(disabled) {
    this.selectEl.prop('disabled', disabled);
    return this;
  };
}

export default BaseSelect2Field;
