import BaseComponent from 'js/base_v2/component';

const staticSelf = DefaultNotifier;

/**
 * @const
 */
staticSelf.TYPE_ERROR = 'error';

/**
 * @const
 */
staticSelf.TYPE_INFO = 'info';

/**
 * @const
 */
staticSelf.TYPE_LOADING = 'loading';

/**
 * @const
 */
staticSelf.TYPE_SUCCESS = 'success';

/**
 * @const
 */
staticSelf.TYPE_WARNING = 'warning';

/**
 * Default Notifier.
 *
 * @class
 * @extends BaseComponent
 *
 * @param {object} [options]
 */
function DefaultNotifier(options) {
  BaseComponent.call(this, options);
  const parent = this.clone();
  const self = this;

  /**
   * Contains all individual notification containers.
   *
   * @protected
   *
   * @prop {DOMElement}
   */
  this._masterContainer = null;

  /**
   * @protected
   *
   * @prop {?DOMElement}
   */
  this._container = null;

  /**
   * Next notification identifier.
   *
   * @protected
   *
   * @prop {number}
   */
  this._nextId = 1;

  /**
   * Timeout IDs map indexed by notification ID.
   *
   * @protected
   *
   * @prop {object}
   */
  this._timeoutIdsMap = {};

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      showAnimationDuration: 250,
      hideAnimationDuration: 250,
      lifeTime: 30000,
      defaultType: staticSelf.TYPE_INFO,
      ctSelector: '.notificationCt',
      ctClassesByType: {
        error: 'error',
        info: 'info',
        loading: 'loading',
        success: 'success',
        warning: 'warning',
      },
      iconClassesByType: {
        error: 'fa-exclamation-circle',
        info: 'fa-info-circle',
        loading: 'fa-spinner fa-pulse fa-3x fa-fw',
        success: 'fa-check-circle',
        warning: 'fa-exclamation-triangle',
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    parent.create.call(this);

    const notificationsCtEl = $('.notificationsCt');

    if (0 !== notificationsCtEl.length) {
      this._masterContainer = notificationsCtEl;

      return this._registerEventListeners();
    }

    this._masterContainer = $('<div>')
      .addClass('notificationsCt notifications-ct');

    $('body').append(this._masterContainer);

    return this._registerEventListeners();
  };

  /**
   * Notify.
   *
   * @param  {?string}         message
   * @param  {string}          [type]
   * @param  {object}          [notificationOpts]
   * @return {DefaultNotifier}
   */
  this.notify = function(message, type, notificationOpts = {}) {
    const notificationOptions = {
      clear: true,
    }.extend(notificationOpts);

    if (notificationOptions.clear) {
      this.clear(false);
    }

    if (_.isNull(message)) {
      return this;
    }

    const validatedType = _.isString(type) ? type : this.options.defaultType;

    const notificationContainer = $(_.template(this._getTmpl())({
      ctClass: this._getContainerClass(validatedType),
      iconClass: this._getIconClass(validatedType),
      message,
    }));

    const notificationId = this._nextId;
    this._nextId += 1;

    notificationContainer.data('id', notificationId);

    this._masterContainer.append(notificationContainer);

    notificationContainer.fadeIn(this.options.showAnimationDuration);

    if (staticSelf.TYPE_LOADING !== validatedType &&
      this.options.lifeTime > 0
    ) {
      this._timeoutIdsMap[notificationId] = setTimeout(() => {
        this._clearNotification(notificationContainer);
      }, this.options.lifeTime);
    }

    return this;
  };

  /**
   * Notify error.
   *
   * @param  {string}          message
   * @return {DefaultNotifier}
   */
  this.notifyError = function(message) {
    return this.notify(message, DefaultNotifier.TYPE_ERROR);
  };

  /**
   * Alias for "notifyError".
   *
   * @param  {string}          message
   * @return {DefaultNotifier}
   */
  this.error = function(message) {
    return this.notifyError(message);
  };

  /**
   * Notify info.
   *
   * @param  {string}          message
   * @return {DefaultNotifier}
   */
  this.notifyInfo = function(message) {
    return this.notify(message, DefaultNotifier.TYPE_INFO);
  };

  /**
   * Alias for "notifyInfo".
   *
   * @param  {string}          message
   * @return {DefaultNotifier}
   */
  this.info = function(message) {
    return this.notifyInfo(message);
  };

  /**
   * Notify loading.
   *
   * @param  {string}          message
   * @return {DefaultNotifier}
   */
  this.notifyLoading = function(message) {
    return this.notify(message, DefaultNotifier.TYPE_LOADING);
  };

  /**
   * Alias for "notifyLoading".
   *
   * @param  {string}          message
   * @return {DefaultNotifier}
   */
  this.loading = function(message) {
    return this.notifyLoading(message);
  };

  /**
   * Notify success.
   *
   * @param  {string}          message
   * @return {DefaultNotifier}
   */
  this.notifySuccess = function(message) {
    return this.notify(message, DefaultNotifier.TYPE_SUCCESS);
  };

  /**
   * Alias for "notifySuccess".
   *
   * @param  {string}          message
   * @return {DefaultNotifier}
   */
  this.success = function(message) {
    return this.notifySuccess(message);
  };

  /**
   * Notify warning.
   *
   * @param  {string}          message
   * @return {DefaultNotifier}
   */
  this.notifyWarning = function(message) {
    return this.notify(message, DefaultNotifier.TYPE_WARNING);
  };

  /**
   * Alias for "notifyWarning".
   *
   * @param  {string}          message
   * @return {DefaultNotifier}
   */
  this.warning = function(message) {
    return this.notifyWarning(message);
  };

  /**
   * Clear all notifications.
   *
   * @param  {boolean}         [animate]
   * @return {DefaultNotifier}
   */
  this.clear = function(animate = true) {
    $(this.options.ctSelector, this._masterContainer).each(function() {
      self._clearNotification($(this), animate);
    });

    return this;
  };

  /**
   * Register event listeners.
   *
   * @protected
   *
   * @return {DefaultNotifier}
   */
  this._registerEventListeners = function() {
    this._masterContainer.on('click', '.closeBtn', function(event) {
      self._onCloseBtnClick($(this), event);
    });

    return this;
  };

  /**
   * Close button click event handler.
   *
   * @protected
   *
   * @param {DOMElement} button
   * @param {Event}      event
   */
  this._onCloseBtnClick = function(button, event) {
    event.preventDefault();

    this._clearNotification(button.closest(this.options.ctSelector));
  };

  /**
   * Clear (single) notification.
   *
   * @param  {DOMElement}      notificationContainer
   * @param  {boolean}         [animate]
   * @return {DefaultNotifier}
   */
  this._clearNotification = function(notificationContainer, animate = true) {
    const notificationId = notificationContainer.data('id');

    if (this._timeoutIdsMap[notificationId] > 0) {
      clearTimeout(this._timeoutIdsMap[notificationId]);

      delete this._timeoutIdsMap[notificationId];
    }

    if (animate) {
      notificationContainer.fadeOut(
        this.options.hideAnimationDuration,
        function() {
          $(this).remove();
        },
      );
    } else {
      notificationContainer.remove();
    }

    return this;
  };

  /**
   * Get notification container template.
   *
   * @protected
   *
   * @return {string}
   */
  this._getTmpl = function() {
    /* eslint-disable max-len */
    return `
      <div class="notificationCt notification-ct default-notification-ct wrapp_lg_alert {{ ctClass }}">
        <button type="button" class="closeBtn close-btn">
          <span aria-hidden="true"><i class="fa fa-times"></i></span>
        </button>
        <span class="message">
          <i class="icon fa {{ iconClass }}"></i> {{ message }}
        </span>
      </div>
    `;
    /* eslint-enable max-len */
  };

  /**
   * Get container class.
   *
   * @protected
   *
   * @param  {string} type
   * @return {string}
   */
  this._getContainerClass = function(type) {
    return this.options.ctClassesByType[type];
  };

  /**
   * Get icon class.
   *
   * @protected
   *
   * @param  {string} type
   * @return {string}
   */
  this._getIconClass = function(type) {
    return this.options.iconClassesByType[type];
  };

  // Initialize
  this.init();
}

export const notifier = new DefaultNotifier().create();

export default DefaultNotifier;
