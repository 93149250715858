import BaseComponent from 'js/base_v2/component';
import ListExtension from 'js/common_v3/extensions/list-extension';
import ViewToggleButton from 'js/user/buttons/view-toggle-button';

/**
 * Base View.
 *
 * @class
 * @abstract
 * @extends BaseComponent
 *
 * @param {object} [options]
 */
function BaseView(options) {
  BaseComponent.call(this, options);
  const parent = this.clone();

  /**
   * @prop {object}
   */
  this.fieldsEnabled = null;

  /**
   * @prop {object}
   */
  this.fieldsRequired = null;

  /**
   * @prop {DOMElement}
   */
  this.container = null;

  /**
   * @prop {ListExtension}
   */
  this.listExtension = null;

  /**
   * @prop {ViewToggleButton}
   */
  this.viewToggleButton = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      alternateUrl: undefined,
      listExtension: {},
      viewToggleButton: {},
    });
  };

  /**
   * @inheritDoc
   */
  this.initProps = function() {
    parent.initProps.call(this);

    this.listExtension = new ListExtension(
      this,
      this.options.listExtension,
    );

    return this;
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    parent.create.call(this);

    if (_.isNull(this.container)) {
      this.container = $(document);
    } else if (_.isString(this.container)) {
      this.container = $(this.container);
    }

    return this
      .registerEventListeners()
      .createComponents();
  };

  /**
   * Register event listeners.
   *
   * @return {BaseView}
   */
  this.registerEventListeners = function() {
    return this;
  };

  /**
   * Reload view.
   *
   * @return {BaseView}
   */
  this.reload = function() {
    return this;
  };

  /**
   * Create (child) components.
   *
   * @return {BaseView}
   */
  this.createComponents = function() {
    return this.createVersionViewToggleButton();
  };

  /**
   * @returns {BaseView}
   */
  this.createVersionViewToggleButton = function() {
    if (_.isUndefined(this.options.alternateUrl)) {
      return this;
    }

    this.viewToggleButton = new ViewToggleButton(
      $('.versionToggleButtonCt', this.container),
      $('.versionToggleButtonCt .versionToggleButton', this.container),
      this.options.viewToggleButton.extend({
        url: this.options.alternateUrl,
      }),
    ).create();

    return this;
  };

  /**
   * Create form.
   *
   * @param  {string}   name
   * @param  {?object}  [record]
   * @param  {object}   [formOptions]
   * @return {BaseList}
   */
  this.createForm = function(name, record = null, formOptions = {}) {
    const formName = `${name}Form`;
    const formClass = this.options[`${formName}Class`];

    this[formName] = new formClass(
      record,
      this.options[formName].extend(formOptions),
    ).create();

    return this;
  };

  /**
   * Create list.
   *
   * @param  {string}      name
   * @param  {?DOMElement} container
   * @return {BaseView}
   */
  this.createList = function(name, container) {
    const listName = `${name}List`;
    const listClass = this.options[`${listName}Class`];

    if (_.isObject(this[listName])) {
      return this;
    }

    this[listName] = new listClass(
      $(this.getListCtSelector(name), container),
      $(this.getTableSelector(name), container),
      this.options[listName],
    ).create();

    return this;
  };

  /**
   * Create view.
   *
   * @param  {string}      name
   * @param  {?DOMElement} container
   * @return {BaseView}
   */
  this.createView = function(name, container) {
    const viewName = `${name}View`;
    const viewClass = this.options[`${viewName}Class`];

    if (_.isObject(this[viewName])) {
      this[viewName].adjust();

      return this;
    }

    this[viewName] = new viewClass(
      $(this.getViewCtSelector(name), container),
      this.options[viewName],
    ).create();

    return this;
  };

  /**
   * Adjust.
   *
   * @return {BaseView}
   */
  this.adjust = function() {
    return this;
  };

  /**
   * Get table selector.
   *
   * @param  {string} name
   * @return {string}
   */
  this.getTableSelector = function(name) {
    if (_.isUndefined(this.options[`${name}TableSelector`])) {
      return this.getDefaultTableSelector(name);
    }

    return this.options[`${name}TableSelector`];
  };

  /**
   * Get list container selector.
   *
   * @param  {string} name
   * @return {string}
   */
  this.getListCtSelector = function(name) {
    if (_.isUndefined(this.options[`${name}ListCtSelector`])) {
      return this.getDefaultListCtSelector(name);
    }

    return this.options[`${name}ListCtSelector`];
  };

  /**
   * Get view container selector.
   *
   * @param {string} name
   * @return {string}
   */
  this.getViewCtSelector = function(name) {
    if (_.isUndefined(this.options[`${name}ViewCtSelector`])) {
      return this.getDefaultViewCtSelector(name);
    }

    return this.options[`${name}ViewCtSelector`];
  };

  /**
   * Get default table selector.
   *
   * @param  {string} name
   * @return {string}
   */
  this.getDefaultTableSelector = function(name) {
    return `${this.getListCtSelector(name)} .${name}Table`;
  };

  /**
   * Get default list container selector.
   *
   * @param  {string} name
   * @return {string}
   */
  this.getDefaultListCtSelector = function(name) {
    return `.${name}ListCt`;
  };

  /**
   * Get default view container selector.
   *
   * @param  {string} name
   * @return {string}
   */
  this.getDefaultViewCtSelector = function(name) {
    return `.${name}ViewCt`;
  };

  /**
   * Get container.
   *
   * @return {DOMElement}
   */
  this.getContainer = function() {
    return this.container;
  };
}

export default BaseView;
