import BaseComponent from 'js/base_v2/component';
import Ajax from 'js/components/ajax';
import Renderer from 'js/components/renderer';
import DefaultNotifier from 'js/components_v2/default-notifier';
/**
 * Base Save Component.
 *
 * @class
 * @abstract
 * @extends BaseComponent
 *
 * @param {DOMElement} ct
 * @param {?object}    options
 */
function BaseSaveComponent(ct, options) {
    BaseComponent.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {DOMElement}
     */
    this.ct = ct;

    /**
     * @inheritDoc
     */
    this.messageSpan = null;

    /**
     * @prop {string}
     */
    this.oldValue = null;

    /**
     * @prop {object}
     */
    this.record = null;

    /**
     * @prop {DefaultNotifier}
     */
    this.notifier = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            entityName: 'record',
            saveUrl: undefined,
            saveParams: {},
            notifier: {},
            onSave: undefined
        });
    };

    /**
     * @inheritDoc
     */
    this.create = function() {
        parent.create.call(this);

        if (_.isString(this.ct)) {
            this.ct = $(this.ct);
        }

        this.messageSpan = $('.messageSpan', this.ct);

        return this.registerEventListeners();
    };

    /**
     * @inheritDoc
     */
    this.createSubcomponents = function() {
        parent.createSubcomponents.call(this);
        return this.createNotifier();
    };

    /**
     * Create notifier.
     *
     * @return {BaseSaveComponent}
     */
    this.createNotifier = function() {
        this.notifier = new DefaultNotifier(this.options.notifier).create();
        return this;
    };

    /**
     * Register event listeners.
     *
     * @return {BaseSaveComponent}
     */
    this.registerEventListeners = function() {
        return this;
    };

    /**
     * Prepare save parameters.
     *
     * @return {BaseSaveComponent}
     */
    this.prepareSaveParams = function() {
        var params = {};

        if (_.isObject(this.record) && !_.isUndefined(this.record.id)) {
            params[this.options.entityName] = {
                id: this.record.id
            };
        }

        this.options.saveParams = this.options.saveParams.extend(params);

        return this;
    };

    /**
     * Save.
     *
     * @return {BaseSaveComponent}
     */
    this.save = function() {
        this
            .notifyLoading()
            .prepareSaveParams();

        var data = this.options.saveParams.extend(this.getData());

        Ajax.post(
            this.options.saveUrl,
            data,
            function(response) {
                self.onSaveSuccess(response);
            },
            function(response, error) {
                self.onSaveError(response, error);
            }
        );

        return this;
    };

    /**
     * Save success event handler.
     *
     * @param  {object}            response
     * @return {boolean|undefined}
     */
    this.onSaveSuccess = function(response) {
        if (_.isFunction(this.options.onSave)) {
            var ret = this.options.onSave(response);

            if (false === ret) {
                return ret;
            }
        }

        this.notifySuccess();
        this.setNewValue(this.getRecordFromResponse(response));

        return undefined;
    };

    /**
     * Save error event handler.
     *
     * @param {object} response
     * @param {string} error
     */
    this.onSaveError = function(response, error) {
        this.notifyError(error);
        this.setOldValue();
    };

    /**
     * Get record from response.
     *
     * @param  {object} response
     * @return {object}
     */
    this.getRecordFromResponse = function(response) {
        return response[this.options.entityName]
    };

    /**
     * Notify error.
     *
     * @param  {string}            error
     * @return {BaseSaveComponent}
     */
    this.notifyError = function(error) {
        this.notifier.notifyError(error);
        this.messageSpan.hide();

        return this;
    };

    /**
     * Notify loading.
     *
     * @return {BaseSaveComponent}
     */
    this.notifyLoading = function() {
        this.messageSpan
            .html(Renderer.Loading())
            .show();

        return this;
    };

    /**
     * Notify success.
     *
     * @return {BaseSaveComponent}
     */
    this.notifySuccess = function() {
        this.messageSpan.hide();
        return this;
    };

    /**
     * Set new value.
     *
     * @abstract
     *
     * @param  {object}            record
     * @return {BaseSaveComponent}
     */
    this.setNewValue = function(record) {
        throw 'BaseSaveComponent.setNewValue is not implemented.';
    };

    /**
     * Set old value.
     *
     * @abstract
     *
     * @return {BaseSaveComponent}
     */
    this.setOldValue = function() {
        throw 'BaseSaveComponent.setOldValue is not implemented.';
    };

    /**
     * Get data.
     *
     * @abstract
     *
     * @return {object}
     */
    this.getData = function() {
        throw 'BaseSaveComponent.getData is not implemented.';
    };
}

export default BaseSaveComponent;
