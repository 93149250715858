import BaseEntity from 'js/base_v3/models/entity';

const staticSelf = BomElement;

/**
 * @const
 */
staticSelf.ID_SHORTAGE = '__shortage__';

/**
 * @const
 */
staticSelf.ID_OVERAGE = '__overage__';

/**
 * @const
 */
staticSelf.NAME_EMPTY = 'No Element Assigned';

/**
 * BoM Element Entity.
 *
 * @class
 * @extends BaseEntity
 *
 * @param {?object} data
 */
function BomElement(data) {
  BaseEntity.call(this, data);

  // Initialize
  this._init();
}

export default BomElement;
