const staticSelf = Translator;

/**
 * @static
 *
 * @prop {object}
 */
/* eslint-disable max-len */
staticSelf.translations = {
  accept_parts_confirmation_message: 'Are you sure you want to continue and accept {{ nParts }} part(s)?',
  accepted: 'accepted',
  accounting_month: 'accounting month',
  accounting_period: 'accounting period',
  add_all_label: 'add all',
  add_alternate_part: 'add alternate part',
  add_element_note: 'add element note',
  add_new_label: 'add new',
  add_note: 'add note',
  add_part: 'add part',
  add_parts_to_pricing_profile: 'add parts to pricing profile',
  add_to_bom: 'add to job',
  add_to_customer_quote: 'add to customer quote',
  add_to_existing_quote_in_progress: 'Adding parts to quote...',
  add_to_existing_quote_success: 'Parts successfully added to the existing quote.',
  add_to_new_quote_label: 'Add to New Quote',
  add_to_new_quote_success: 'Parts successfully added to new quote.',
  add_to_new__quoteType__quote_label: 'add to new {{ quoteType }} quote',
  add_to_quote: 'add to quote',
  add_to_vendor_quote: 'add to vendor quote',
  agreement_acceptance_message: 'I agree to the ',
  algodocs_extract_data_in_progress: 'Extracting data...',
  all: 'all',
  all_boms: 'All Jobs',
  archived: 'Archived',
  attach_existing_quote_error_prefix: 'An error occurred while attaching the quote',
  attach_existing_quote_in_progress: 'Attaching quote...',
  attach_quote_in_progress: 'Attaching quote...',
  attach_quote_to_costing_profile_error_prefix: 'An error occurred while attaching quotes to costing profile',
  attach_quote_to_costing_profile_success: 'Quotes successfully attached to costing profile.',
  attach_quote_to_project_error_prefix: 'An error occurred while attaching quotes to the project',
  attach_quote_to_project_success: 'Quotes successfully attached to the project.',
  attach_quote_to_quote_error_prefix: 'An error occurred while attaching the quote',
  attach_quote_to_quote_success: 'Quote successfully attached.',
  bom: 'Job',
  bom_customer_company_association: 'customer',
  bom_name: 'Job Name',
  bom_name_cannot_be_empty: 'Job name cannot be empty.',
  bom_part: 'BoM part',
  bom_parts: 'BoM parts',
  bom_price_decimals: 'BoM price decimals',
  bom_vendor_company_association: 'vendor',
  boms: 'Jobs',
  canceled: 'canceled',
  change_parts_manufacturer_confirmation_message: 'Are you sure you want to continue and change the manufacturer for {{ nParts }} part(s)?',
  change_user_version_error_prefix: 'An error occurred while changing the version: ',
  change_user_version_in_progress: 'Changing version...',
  choose_apply_pricing_to_parts_message: 'Apply change to',
  choose_part_number: 'choose part number',
  choose_quote_listing_type_message: 'What are you looking for?',
  city_label: 'City',
  click_to_edit: 'click to edit',
  close_confidence_label: 'Close Confidence',
  close_date_label: 'Close Date',
  collapse_all_elements: 'collapse all elements',
  collapse_element: 'collapse element',
  company_manufacturer_id: 'company manufacturer ID',
  company_manufacturer_name: 'company manufacturer name',
  company_part_number: 'company part number',
  company_settings_save_error_prefix: 'An error occurred while saving integration settings: ',
  company_settings_save_in_progress: 'Saving integration settings...',
  company_settings_save_success: 'Integration settings have been successfully saved.',
  company_uom_conversion: 'UOM conversion',
  company_uom_conversion_from_uom: 'base UOM',
  company_uom_conversion_multiplier: 'multiplier',
  company_uom_conversion_to_uom: 'alternate UOM',
  company_users_with_access_to_project: 'Internal company users with access to this project.',
  competitors_label: 'Competitors',
  component_note: 'note',
  component_set_part_and_change_uom_confirmation_message: 'Component\'s unit of measure will be changed from <i>{{ currentUom }}</i> to <i>{{ newUom }}</i>. Are you sure you want to choose the part <b>{{ partNumber }}</b>?',
  component_template: 'component/part',
  component_templates: 'components/parts',
  configurator: 'configurator',
  confirmation_question_prefix: 'Are you sure you want to',
  contact_access_revoked: 'Contact\'s access has been successfully revoked.',
  contact_company_save_accounts_receivable_id_error: 'An error occurred while saving the accounts receivable ID',
  contact_company_save_accounts_receivable_id_in_progress: 'Saving accounts receivable ID...',
  contact_company_save_accounts_receivable_id_success: 'Accounts receivable ID saved successfully.',
  contact_company_save_customer_number_error: 'An error occurred while saving the customer number',
  contact_company_save_customer_number_in_progress: 'Saving customer number...',
  contact_company_save_customer_number_success: 'Customer number saved successfully.',
  contact_invited_as_visitor: 'Contact has been successfully invited as visitor.',
  convert_uom: 'Convert UOM',
  core_integration_label: 'CORE Integration',
  core_integration_label__short: 'CORE',
  core_or_ddp_integration_label: 'CORE/DDP Integration',
  corporate_alternate_vendor_number: 'SKU',
  corporate_part_note: 'note',
  corporate_part_notes: 'notes',
  corporate_discount: 'discount',
  corporate_location: 'location',
  corporate_locations: 'locations',
  corporate_region: 'region',
  corporate_regions: 'regions',
  corporate_part: 'company part',
  corporate_part_external_uom: 'external/ERP UOM',
  corporate_part_alternate_uom: 'SKU UOM',
  corporate_part_manufacturer_minimum_quantity: 'Vendor Minimum Order Quantity',
  corporate_part_manufacturer_order_increment: 'vendor order increment',
  corporate_part_minimum_quantity: 'Sales Minimum Order Quantity',
  corporate_part_order_increment: 'sales order increment',
  corporate_part_package_uom: 'package UOM',
  corporate_part_purchase_cost: 'purchase cost',
  corporate_part_purchase_cost_uom: 'purchase UOM',
  corporate_part_sales_uom: 'sales UOM',
  corporate_parts_validation_error_prefix: 'An error occurred while validating corporate parts',
  corporate_parts_validation_success: 'Corporate parts have been successfully validated.',
  corporate_shipment_type: 'shipment type',
  corporate_user: 'user',
  corporate_users: 'users',
  cost_end_date: 'cost end date',
  cost_note: 'cost note',
  cost_start_date: 'cost start date',
  costing_profile: 'costing profile',
  create_new_fixed_pricing_item: 'add new fixed cost/price',
  create_order_from_quote_confirmation_message: 'Are you sure you want to create an order?',
  current_user_me_label: 'me',
  customer: 'customer',
  customer_contact_group: 'customer',
  customer_order_label: 'customer order',
  customer_part_number: 'customer part number',
  customer_part_number_label: 'c',
  customer_quote_label: 'customer quote',
  ddp_integration_label: 'DDP Integration',
  ddp_integration_label__short: 'DDP',
  declined: 'declined',
  default_vendor: 'default vendor',
  default_vendors: 'default vendors',
  detach_vendor_quotes_error: 'An error occurred while detaching the vendor quote(s)',
  detach_vendor_quotes_in_progress: 'Detaching vendor quote(s)...',
  detach_vendor_quotes_success: 'Vendor quote(s) successfully detached.',
  discount_type_base_code: 'base code',
  discount_type_base_code_vendor_quote_part: 'base code vendor quote part',
  discount_type_current_quote_part_discount: 'part discount',
  discount_type_current_quote_pricing_group_discount: 'pricing group discount',
  discount_type_discount: 'discount',
  discount_type_fixed: 'fixed',
  discount_type_margin: 'margin',
  discount_type_vendor_quote_part: 'vendor quote part',
  discount_type_vendor_quote_part_discount: 'part discount',
  discount_type_vendor_quote_pricing_group_discount: 'pricing group discount',
  displayed: 'displayed',
  document_label: 'document',
  documents_label: 'documents',
  draft: 'draft',
  'draft-customer': 'draft',
  'draft-vendor': 'draft',
  element_component_note: 'note',
  element_template: 'element template',
  element_template_add_to_bom: 'add element template to job',
  element_template_add_to_bom_in_progress: 'Adding Element Template to Job...',
  element_template_add_to_bom_success: 'Element template has been successfully added to job.',
  element_templates: 'element templates',
  end_date_greater_than_start_date_error_message: 'End date should be greater than start date.',
  entered_cost_label: 'entered cost',
  estimated_value_label: 'Estimated Value',
  expand_all_elements: 'expand all elements',
  expand_element: 'expand element',
  export_external_products_error_prefix: 'An error occurred while exporting external parts',
  export_external_products_in_progress: 'Exporting external parts...',
  export_external_products_success: 'External parts have been successfully exported.',
  export_field: 'export field',
  export_file_in_progress: 'Generating export file...',
  external_company_part: 'external part',
  external_customers: 'ext. customers',
  external_product: 'external part',
  external_program_name: 'ext. program name',
  external_program_number: 'ext. program number',
  external_project_name: 'ext. project name',
  external_project_number: 'ext. project number',
  external_project_type: 'ext. type',
  external_approved_funding_amount: 'ext. approved funding amount',
  external_consumed_funding_amount: 'ext. consumed funding amount',
  external_requested_funding_amount: 'ext. requested funding amount',
  fetch_quote_freight_values_error_prefix: 'An error occurred while fetching the quote freight values',
  file_attached_to_bom_success: 'File has been successfully attached to the job.',
  filtered: 'filtered',
  floor_price: 'floor price',
  freight_label: 'freight',
  freight_status_combined: 'combined',
  freight_status_omitted: 'omitted',
  freight_status_prepaid_and_charge: 'prepaid & charge',
  freight_status_spread: 'spread',
  from_project: 'from the project',
  generate_bom_log_in_progress: 'Generating time log export file...',
  generate_datasheet_archive_in_progress: 'Generating datasheet archive...',
  generate_reconciliation_report_in_progress: 'Generating reconciliation report...',
  get_order_info_from_core_error: 'An error occurred while getting order info from CORE.',
  get_orders_info_from_core_error: 'An error occurred while getting orders info from CORE.',
  global_uom_conversion: 'UOM conversion',
  global_uom_conversion_from_uom: 'base UOM',
  global_uom_conversion_multiplier: 'multiplier',
  global_uom_conversion_to_uom: 'alternate UOM',
  go_back_label: 'go back',
  go_to_bom_label: 'see job',
  go_to_pricing_profile_label: 'review/add access',
  go_to_quote_label: 'see quote',
  import_header_choose_manufacturer: 'Choose manufacturer',
  import_header_multiple_manufacturers: 'Multiple manufacturers found. Select the correct manufacturer.',
  import_header_cost: 'cost',
  import_header_cost_description: 'cost note',
  import_header_manufacturer: 'manufacturer',
  import_header_notes: 'notes',
  import_header_part_description: 'description',
  import_header_part_number: 'part number',
  import_header_price: 'price',
  import_header_purchase_cost: 'purchase cost',
  import_header_quantity: 'qty',
  import_header_sell_price: 'sell price',
  import_header_uom: 'UOM',
  imported: 'imported',
  importing_file: 'Importing file...',
  included_label: 'included',
  initialize_quote_draft_version_error_prefix: 'An error occurred while loading draft version',
  initialize_quote_draft_version_in_progress: 'Loading draft version...',
  integration_test_error: 'Integration has not been configured properly.',
  integration_test_success: 'Integration has been successfully tested.',
  inventory_available_for: 'Inventory Available for',
  inventory_standard_cost: 'inventory standard cost',
  inventory_uom: 'Unit of Measure',
  inventory_uom_conversion_missing: 'UOM conversion needs to be set up',
  inviting_contact_as_visitor: 'Inviting contact as visitor...',
  label_access_type: 'access',
  label_access_type_company: 'public',
  label_access_type_user: 'private',
  label_accounts_payable_id__short: 'accts. payable ID',
  label_accounts_receivable_id__short: 'accts. receivable ID',
  label_activity_date: 'activity date',
  label_add: 'add',
  label_add_component_name: 'add component name',
  label_add_cost_discount: 'add new cost discount',
  label_add_cost_note: 'add cost note',
  label_add_discount: 'add discount',
  label_add_document: 'add new document',
  label_add_export_field_mapping: 'add export field mapping',
  label_add_margin: 'add margin',
  label_add_selected: 'Add Selected',
  label_add_task_type: 'add task type',
  label_address_accounts_receivable_id: 'account #',
  label_address_first_line: 'address line 1',
  label_address_name: 'address name',
  label_address_second_line: 'address line 2',
  label_agreement_id: 'agreement ID',
  label_algodocs: 'Import Service',
  label_algodocs_folder_id: 'Folder ID',
  label_all: 'all',
  label_all_bom_statuses: 'all statuses',
  label_all_disciplines: 'all disciplines',
  label_all_invalid_parts: 'Not valid',
  label_all_matched_and_unmatched: 'all (matched & unmatched)',
  label_all_parts: 'all parts',
  label_alternate_external_manufacturer_name_short: 'Alt. External Manufacturer Name',
  label_applying_cost_discount_in_progress: 'Applying cost discount...',
  label_apply: 'apply',
  label_apply_cost_discount: 'apply cost discount',
  label_archive: 'archive',
  label_assign_company_manufacturer: 'Assign Vendor',
  label_association: 'association',
  label_attach_existing_vendor_quote: 'Attach Existing Vendor Quote',
  label_attach_selected_vendor_quotes: 'Attach Selected Vendor Quotes',
  label_attach_to: 'attach to',
  label_attach_to_bom: 'Attach to job',
  label_attach_to_project: 'Attach to project',
  label_attach_to_quote: 'Attach to quote',
  label_attached_to: 'attached to',
  label_automatically_change_to_lost_status_limit: 'Change to lost status after (days)',
  label_back: 'back',
  label_back_up_user: 'back up user',
  label_base_code: 'base code',
  label_base_code_part_form_title: 'Select Part Number(s) for Base Code',
  label_base_uom: 'base uom',
  label_bom_attached_file: 'attached file',
  label_bom_details_job_customer_name: 'customer name',
  label_bom_details_job_requested_by_date: 'due date',
  label_bom_details_job_requested_by_user: 'requested by user',
  label_bom_details_job_requested_by_user_employee_id: 'requested by user - employee ID',
  label_bom_details_job_requirements: 'requirements',
  label_bom_details_job_support_user_assigned: 'support user assigned',
  label_bom_status_priority: 'priority',
  label_boms: 'jobs',
  label_burdened_cost: 'burdened cost',
  label_button_here: 'here',
  label_copy_to_bom: 'copy to job',
  label_calculated_cost: 'calculated cost',
  label_cancel: 'cancel',
  label_category: 'category',
  label_cc: 'cc',
  label_change_company_manufacturer: 'assign vendor',
  label_change_location: 'change location',
  label_change_shipment_type: 'change shipment type',
  label_choose_partfiniti_part_number__short: 'choose Partfiniti part #',
  label_clear: 'Clear',
  label_clear_product_filters: 'Clear All Product Filters',
  label_column: 'column',
  label_company: 'company',
  label_company_cost: 'Company Cost',
  label_company_costing: 'company costing',
  label_company_manufacturer_id: 'vendor ID',
  label_company_manufacturer_name: 'vendor name',
  label_confirm_quantities_and_punchout: 'Confirm Quantities and PunchOut',
  label_contact: 'contact',
  label_continue: 'continue',
  label_copy_or_move: 'copy or move',
  label_corporate_bom_status: 'job status',
  label_corporate_cost_last_update: 'company cost updated',
  label_corporate_discipline: 'discipline',
  label_corporate_disciplines: 'disciplines',
  label_corporate_part_field: 'company part field',
  label_corporate_part_field_class_name: 'Class',
  label_corporate_part_field_company_part_status: 'Company Part Status',
  label_corporate_part_field_criteria: 'Company Part Specification Criteria',
  label_corporate_part_field_external_uom: 'External/ERP UOM',
  label_corporate_part_field_mfg_part_status: 'Manufacturer Part Status',
  label_corporate_part_field_package_type: 'Type',
  label_corporate_part_field_package_uom: 'Package UOM',
  label_corporate_part_field_part_base_code: 'Part Base Code',
  label_corporate_part_field_pkg_units: 'Package Quantity',
  label_corporate_part_field_pricing_group: 'Pricing Group',
  label_corporate_part_field_replacement_part: 'Replacement Part',
  label_corporate_part_field_sales_uom: 'Sales UOM',
  label_corporate_part_field_taa_status: 'TAA Status',
  label_corporate_part_field_uom: 'UOM',
  label_cost: 'cost',
  label_cost_discount: 'cost discount',
  label_cost_valid_max_days__long: 'cost valid for (days)',
  label_costing: 'costing',
  label_costing_or_pricing_profile: 'costing/pricing profile',
  label_costing_profile: 'costing profile',
  label_costing_profile_removed: 'costing profile removed',
  label_costing_profiles: 'costing profiles',
  label_cost_source_type: 'cost source type',
  label_cost_source_type_corporate__short: 'CP',
  label_cost_source_type_corporate__long: 'company parts',
  label_cost_source_type_corporate_qty_discounts__short: 'QD',
  label_cost_source_type_corporate_qty_discounts__long: 'quantity discount',
  label_cost_source_type_custom__short: 'ME',
  label_cost_source_type_custom__long: 'manually entered',
  label_cost_source_type_customer_corporate__short: 'VD',
  label_cost_source_type_customer_corporate__long: 'vendor quote',
  label_cost_source_type_customer_quote__short: 'CQ',
  label_cost_source_type_customer_quote__long: 'this quote',
  label_cost_source_type_default__short: 'PC',
  label_cost_source_type_default__long: 'costing profile',
  label_cost_source_type_external__short: 'EX',
  label_cost_source_type_external__long: 'external',
  label_cost_source_type_family__short: 'PB',
  label_cost_source_type_family__long: 'configurator',
  label_cost_source_type_project__short: 'PP',
  label_cost_source_type_project__long: 'project profile',
  label_cost_source_type_vendor__short: 'VQ',
  label_cost_source_type_vendor__long: 'vendor quote',
  label_costs: 'costs',
  label_country_required: 'country *',
  label_create_export_mapping_field: 'create export mapping field',
  label_create_new_vendor_quote: 'Create New Vendor Quote',
  label_create_part__pricingType__: 'Creating {{ pricingType }}...',
  label_create_task_type: 'create task type',
  label_created: 'created',
  label_customer: 'customer',
  label_customer_company_name: 'customer company name',
  label_default: 'default',
  label_default_corporate_role: 'default user role',
  label_default_freight_percent_of_total_price: 'Default Freight % of Total Price (%)',
  label_default_minimum_freight_charge: 'Minimum Freight Charge ($)',
  label_default_part_class: 'Default Part Class',
  label_default_user: 'default user',
  label_description: 'description',
  label_detach_selected: 'Detach Selected',
  label_details: 'details',
  label_direct_labor: 'direct labor',
  label_discount: 'discount',
  label_discount_type: 'adjustment type',
  label_discount_type_base_code__short: 'BC',
  label_discount_type_base_code__long: 'base code price',
  label_discount_type_base_code_vendor_quote_part__short: 'BC',
  label_discount_type_base_code_vendor_quote_part__long: 'base code price',
  label_discount_type_current_quote_part_discount_off_list_price__short: 'JL',
  label_discount_type_current_quote_part_discount_off_list_price__long: 'this quote part specific discount off list price',
  label_discount_type_current_quote_part_discount_off_std_cost__short: 'JS',
  label_discount_type_current_quote_part_discount_off_std_cost__long: 'this quote part specific discount off standard cost',
  label_discount_type_current_quote_pricing_group_discount_off_list_price__short: 'HL',
  label_discount_type_current_quote_pricing_group_discount_off_list_price__long: 'this quote pricing group discount off list price',
  label_discount_type_current_quote_pricing_group_discount_off_std_cost__short: 'HS',
  label_discount_type_current_quote_pricing_group_discount_off_std_cost__long: 'this quote pricing group discount off standard cost',
  label_discount_type_fixed_cost__short: 'FC',
  label_discount_type_fixed_cost__long: 'fixed cost',
  label_discount_type_discount_off_list_price__short: 'DL',
  label_discount_type_discount_off_list_price__long: 'discount off list price',
  label_discount_type_discount_off_std_cost__short: 'DS',
  label_discount_type_discount_off_std_cost__long: 'discount off standard cost',
  label_discount_type_vendor_quote_part__short: 'PN',
  label_discount_type_vendor_quote_part__long: 'vendor quote part price',
  label_discount_type_vendor_quote_part_discount_off_list_price__short: 'PL',
  label_discount_type_vendor_quote_part_discount_off_list_price__long: 'vendor quote part specific discount off list price',
  label_discount_type_vendor_quote_part_discount_off_std_cost__short: 'PS',
  label_discount_type_vendor_quote_part_discount_off_std_cost__long: 'vendor quote part specific discount off standard cost',
  label_discount_type_vendor_quote_pricing_group_discount_off_list_price__short: 'GL',
  label_discount_type_vendor_quote_pricing_group_discount_off_list_price__long: 'vendor quote pricing group discount off list price',
  label_discount_type_vendor_quote_pricing_group_discount_off_std_cost__short: 'GS',
  label_discount_type_vendor_quote_pricing_group_discount_off_std_cost__long: 'vendor quote pricing group discount off standard cost',
  label_duration_in_hours: 'time (hrs)',
  label_edit_export_field_mapping: 'edit export field mapping',
  label_edit_log: 'edit log',
  label_edit_name: 'edit name',
  label_edit_task_type: 'edit task type',
  label_element_note: 'element note',
  label_email: 'email',
  label_email_address: 'email address',
  label_enable: 'enable',
  label_end_date: 'end date',
  label_entered_quantity: 'entered quantity',
  label_error: 'An error occurred',
  label_estimated_close_date: 'estimated close date',
  label_expiry_date: 'expiry date',
  label_export: 'export',
  label_export_bom_log: 'export time log',
  label_export_filtered: 'export filtered',
  label_export_selected: 'export selected',
  label_export_uom_conversion_filtered: 'export filtered UOM conversions',
  label_export_uom_conversion_selected: 'export selected UOM conversions',
  label_external_company_part: 'external part',
  label_external_company_parts: 'external parts',
  label_external_customer_number: 'Customer Number',
  label_external_location_id: 'Location ID',
  label_external_location_id__short: 'Loc. ID',
  label_external_manufacturer_name: 'External Manufacturer Name',
  label_external_order_number: 'External Order Number',
  label_external_pending_order_number: 'External Pending Order Number',
  label_external_user_id: 'External User ID',
  label_field: 'field',
  label_find_part_number: 'Find a Part Number:',
  label_finish: 'finish',
  label_finish_date: 'finish date',
  label_finish_time: 'finish time',
  label_fixed_price: 'fixed price',
  label_fixed_prices: 'fixed prices',
  label_fixed_pricing: 'fixed cost/price',
  label_floor_price: 'Floor Price',
  label_follow_up: 'follow up',
  label_follow_up_quote_limit: 'Follow up on quote after (days)',
  label_for_customer: 'For Customer',
  label_from_vendor: 'From Vendor',
  label_generate_reconciliation_report: 'generate reconciliation report',
  label_get_cost: 'get cost',
  label_get_order_url: 'get order URL',
  label_hours: '(hrs)',
  label_import: 'import',
  label_import_vendor_quote: 'import vendor quote',
  label_in_progress: 'Saving...',
  label_include_no_pricing: 'Confirm / Include no cost lines',
  label_invalid_cost: 'Old/expired cost',
  label_invalid_gpm: 'GPM out of range',
  label_invalid_moq: 'Below MOQ',
  label_invalid_order_increment: 'Invalid increment',
  label_invalid_quantity_uom: 'Check quantity vs UOM',
  label_inventory: 'inventory',
  label_inventory_type_company_available: 'In stock at other locations.',
  label_inventory_type_user_available: 'In stock at my location.',
  label_inventory_uom: 'inventory UOM',
  label_label: 'label',
  label_lead_time_note: 'lead time note',
  label_list_price: 'List Price',
  label_location_country: 'location country',
  label_log_time: 'log time',
  label_main_contact_group: 'main contact company',
  label_manufacturer: 'manufacturer',
  label_manufacturer_part_class: 'part class',
  label_margin: 'margin',
  label_margin_warning_range: 'Margin Warning Range (%)',
  label_merge_contact_groups: 'merge contact companies',
  label_move: 'move',
  label_move_to: 'move to',
  label_name: 'name',
  label_next: 'next',
  label_no_customer: 'No Customer',
  label_no_dates: 'no specific dates',
  label_no_manufacturer: 'no manufacturer',
  label_none: 'none',
  label_note: 'note',
  label_notes: 'notes',
  label_omit_no_pricing: 'Confirm / Omit no cost lines',
  label_operator_begins_with: 'begins with',
  label_operator_contains: 'contains',
  label_operator_eq: 'eq',
  label_operator_ends_with: 'ends with',
  label_operator_gt: 'gt',
  label_operator_gte: 'gte',
  label_operator_lt: 'lt',
  label_operator_lte: 'lte',
  label_operator_ne: 'ne',
  label_order_base_url: 'order base URL',
  label_order_part: 'order part',
  label_order_parts: 'order parts',
  label_original_quantity: 'original quantity',
  label_overhead: 'overhead',
  label_owner: 'owner',
  label_maximum: 'maximum',
  label_maximum_short: 'max',
  label_minimum: 'minimum',
  label_minimum_short: 'min',
  label_minimum_order_threshold: 'Minimum Order Threshold ($)',
  label_part_base_code: 'part base code',
  label_part_class: 'class',
  label_part_mfg_status: 'manufacturer part status',
  label_part_number: 'part number',
  label_part_pricing_group: 'pricing group',
  label_part_pricing_group__index__: 'pricing group {{ index }}',
  label_part_pricing_group_description__index__: 'pricing group description {{ index }}',
  label_part_replacement: 'replacement part',
  label_part_status: 'company part status',
  label_part_taa_status: 'TAA status',
  label_partfiniti_part_number: 'Partfiniti part number',
  label_per_base_uom_affix: '[per base UOM]',
  label_per_purchase_uom_affix: '[per purchase UOM]',
  label_price: 'price',
  label_prices: 'prices',
  label_pricing: 'pricing',
  label_pricing_factor: 'pricing factor',
  label_pricing_group_discount: 'pricing group discount',
  label_pricing_group_part_form_title: 'Select Part Number(s) for Pricing Group',
  label_pricing_profile: 'pricing profile',
  label_pricing_profile_costing_calculation: 'costing calculation',
  label_pricing_profile_document_name: 'name',
  label_pricing_profile_margins_and_discounts: 'pricing calculation',
  label_pricing_profile_removed: 'pricing profile removed',
  label_pricing_profiles: 'pricing profiles',
  label_pricing_source_custom: 'manually entered',
  label_pricing_source_customer_corporate: 'customer corporate',
  label_pricing_source_customer_quote: 'customer quote',
  label_pricing_source_default: 'company default pricing',
  label_pricing_source_floor_price: 'floor price',
  label_pricing_source_list_price: 'list price',
  label_pricing_source_project: 'project pricing',
  label_pricing_source_vendor_quote: 'vendor quote',
  label_privacy_policy: 'Privacy Policy',
  label_project_type: 'type',
  label_punchout: 'PunchOut Now',
  label_purchase_cost: 'Purchase Cost',
  label_purchase_uom_affix: '[purchase UOM]',
  label_quantity: 'quantity',
  label_quantity_short: 'qty',
  label_quantity_uom_warning_threshold: 'Quantity/UOM Warning Threshold',
  label_quote: 'quote',
  label_quote_external_number: 'vendor quote number',
  label_quote_external_project_name: 'vendor project name',
  label_quote_external_revision: 'vendor quote revision',
  label_quote_location: 'quoted location',
  label_quote_status_all: 'all statuses',
  label_quote_status_archived: 'archived',
  label_quote_status_declined: 'declined',
  label_quote_status_draft: 'draft',
  label_quote_status_editable_by_customer: 'imported',
  label_quote_status_lost: 'lost',
  label_quote_status_open: 'open',
  label_quote_status_open_draft: 'open & draft',
  'label_quote_status_open|draft': 'open & draft',
  label_quote_status_ordered: 'ordered',
  label_quote_status_punched_out: 'punched out',
  label_quote_status_won: 'ordered',
  label_quotes: 'quotes',
  label_rate: 'rate',
  label_reassign_quote: 'reassign quote',
  label_reattach_selected: 'Reattach Selected',
  label_remove: 'remove',
  label_remove_export_field_mapping: 'remove export field mapping',
  label_remove_log: 'remove log',
  label_remove_task_type: 'remove task type',
  label_requested_by_user: 'requested by',
  label_required_close_date: 'required close date',
  label_resend: 'resend',
  label_resend_email_verification_link: 'resend email verification link',
  label_reset_filters: 'reset filters',
  label_reset_pricing: 'reset pricing',
  label_restore: 'restore',
  label_roles: 'roles',
  label_sales_location_id: 'sales location ID',
  label_sales_report: 'sales report',
  label_save: 'save',
  label_save_as_template: 'save as template',
  label_save_customer_number_to_bom: 'Save as Job Customer Number',
  label_save_customer_number_to_company: 'Save as Company Customer Number',
  label_save_customer_number_to_location: 'Save as Location Customer Number',
  label_save_customer_number_to_order: 'Use for this order only',
  label_save_export_field_mapping: 'save export field mapping',
  label_save_log: 'save log',
  label_save_task_type: 'save task type',
  label_save_uom_conversion: 'Save UOM Conversion',
  label_search: 'Search',
  label_search_for_vendor: 'Search for Vendor',
  label_select_date: 'select date',
  label_select_part_number: 'Select Part Number(s)',
  label_select_user: 'select user',
  label_select_task: 'select task',
  label_send: 'send',
  label_sign_in: 'sign in',
  label_sign_up: 'sign up',
  label_since: 'since',
  label_sku: 'SKU',
  label_sku_uom: 'SKU UOM',
  label_special_cost: 'special cost',
  label_special_standard_cost: 'special std cost',
  label_standard_cost: 'Standard Cost',
  label_start: 'start',
  label_start_date: 'start date',
  label_start_time: 'start time',
  label_state: 'state ',
  label_state_required: 'state *',
  label_status: 'status',
  label_structure_type_discounts: 'discount',
  label_structure_type_margins: 'margin',
  label_success: 'Saved successfully.',
  label_suggested_quantity: 'suggested quantity',
  label_supplier_id: 'Supplier ID',
  label_supplier_site_id: 'Supplier Site ID',
  label_support_completed_date: 'support completed date',
  label_support_level: 'support level',
  label_support_level_name: 'level',
  label_support_priority: 'priority',
  label_support_request_status: 'request status',
  label_support_request_type: 'request type',
  label_support_request_type_default_level: 'default level',
  label_support_user: 'support user',
  label_target_margin_threshold: 'Target Margin Threshold (%)',
  label_task: 'task',
  label_taxonomy_level: 'taxonomy level',
  label_theatre: 'theatre',
  label_time: 'time',
  label_tos: 'Terms of Service',
  label_total: 'total',
  label_totals: 'totals',
  label_type: 'type',
  label_until: 'until',
  label_unverified_part: 'unverified part',
  label_uom: 'UOM',
  label_uom_mismatch: 'UOM mismatch',
  label_uoms: 'UOMs',
  label_update: 'update',
  label_update_part: 'update part',
  label_update_user_information: 'update user information',
  label_user: 'user',
  label_valid: 'valid',
  label_validate_filtered: 'validate filtered',
  label_validate_selected: 'validate selected',
  label_vendor_project_name: 'vendor project name',
  label_vendor_quote: 'vendor quote',
  label_vendor_quote_number: 'vendor quote number',
  label_vendor_quote_valid_until: 'vendor quote valid until',
  label_vendor_quotes: 'vendor quotes',
  label_vendors: 'vendors',
  label_vertical_market: 'vertical market',
  lead_time: 'lead time',
  list_price: 'list price',
  load_more: 'Load More',
  loading_customer_data: 'Loading customer data...',
  loading_vendor_data: 'Loading vendor data...',
  location_label: 'Location',
  manufacturer_part_number: 'manufacturer part number',
  message_label: 'Message',
  message_saving: 'saving...',
  message_removing: 'removing...',
  message_resend_email_verification_link: 'If you would like us to resend the email click {{ button }}.',
  message_success: 'success!',
  message_failure: 'operation failed.',
  minimum_order_quantity__short: 'moq',
  modal_confirmation_btn: 'Confirm',
  moving_quote_parts: 'moving quote parts',
  no_corporate_location_assigned: 'no location',
  no_export_field_mappings: 'No export field mappings available',
  no_location_label: 'No Location',
  no_logs: 'No logs available',
  no_quote: 'No Quote',
  no_shipment_type_label: 'No Shipment Type',
  no_task_types: 'No task types available',
  no_translations: 'No valid translation found.',
  no_value: 'no value',
  not_available_label: 'N/A',
  note: 'note',
  note_confirmation_message: 'This message will be available for the customer. Are you sure you want to save it?',
  order: 'order',
  ordered: 'ordered',
  order_increment__short: 'incr',
  order_note_label: 'Order Note',
  order_note_type_customer: 'Customer Order Note',
  order_note_type_internal: 'Internal Order Note',
  package__short: 'pack',
  package_quantity: 'package quantity',
  part: 'part',
  part__applyTo__discount_note: 'part {{ applyTo }} discount',
  part_customer_association: 'customer part number',
  part_engineering_number: 'engineering',
  part_field_criteria_label: 'Part Specification Criteria',
  part_not_found: 'Part has not been found.',
  part_ordering_number: 'ordering',
  part_save_error_prefix: 'An error occurred while saving part',
  part_uom_conversion: 'UOM conversion',
  part_uom_conversion_from_uom: 'base UOM',
  part_uom_conversion_multiplier: 'multiplier',
  part_uom_conversion_to_uom: 'alternate UOM',
  part_validate_error_prefix: 'An error occurred while validating part',
  part_validate_in_progress: 'Validating part...',
  parts: 'parts',
  parts_added_to_bom_label: 'The parts have been successfully added to the specified job.',
  parts_added_to_pricing_profile_label: 'The parts have been successfully added to the pricing profile. Would you like to review or add customer access to this pricing profile?',
  parts_added_to_quote_label: 'The parts have been successfully added to the quote.',
  parts_components: 'Part(s) / Component(s)',
  pending_order_base_url_label: 'Pending Order Base URL',
  pending_order_url_label: 'Submit Pending Order URL',
  prepaid_and_charge_label: 'prepaid & charge',
  price_save_success: 'Price has been successfully updated.',
  pricing_access_item: 'access',
  pricing_group__applyTo__discount_note: 'pricing group {{ applyTo }} discount',
  pricing_profile: 'pricing profile',
  pricing_profile_all_customers: 'all customers',
  pricing_profile_all_customer_locations: 'all locations',
  pricing_profile_all_locations: 'all locations',
  pricing_profile_all_regions: 'all regions',
  pricing_profile_all_users: 'all users',
  pricing_profile_customers: 'customers',
  pricing_profile_edit_allowed_only_on_pricing_page: 'This pricing profile could be used for many customers on many quotes. Please make changes on the Pricing Profiles page (click <a href="{{ pricingProfileUrl }}" target="_blank">here</a> or the <svg><use xlink:href="#icon-usd-circle-solid"></use></svg> icon on the top right of the page).',
  pricing_profile_locations: 'locations',
  pricing_profile_quote_association: 'pricing profile - quote association',
  pricing_profile_regions: 'regions',
  pricing_profile_type_default: 'Day to Day',
  pricing_profile_type_project: 'Project',
  pricing_profile_users: 'users',
  project_save_error_prefix: 'An error occurred while saving the project',
  project_save_success: 'Project saved successfully.',
  project_label: 'Project',
  project_name_label: 'Project Name',
  projects_label: 'Projects',
  purchase_order_number: 'purchase order number',
  quote: 'quote',
  quote_contact: 'quote contact',
  quote_draft_version_release_confirmation_btn: 'Send / Release',
  quote_draft_version_release_confirmation_message: 'Are you sure you want to release the draft?',
  quote_draft_version_release_confirmation_note_label: 'Message (optional)',
  quote_draft_version_release_confirmation_attach_pdf_label: 'Attach PDF',
  quote_draft_version_release_confirmation_attach_xls_label: 'Attach XLS',
  quote_draft_version_reset_confirmation_message: 'Are you sure you want to reset the draft? Please note that all the changes you have made will be lost.',
  quote_history_for: 'Quote History for',
  quote_listing_default_type_save_error: 'An error occurred while saving default quote listing type',
  quote_note_label: 'Quote Note',
  quote_note_type_customer: 'Customer Quote Note',
  quote_note_type_internal: 'Internal Quote Note',
  quote_part: 'quote part',
  quote_part_save_in_progress: 'Saving quote part...',
  quote_part_save_error_prefix: 'An error occurred while saving the quote part',
  quote_part_save_success: 'Part saved successfully.',
  quote_parts: 'quote parts',
  quote_parts_moved_successfully: 'quote parts moved successfully',
  quote_price_decimals: 'quote price decimals',
  quote_version_release_error_prefix: 'An error occurred while releasing quote version',
  quote_version_release_in_progress: 'Releasing quote version...',
  quote_version_release_success: 'Quote version has been successfully released.',
  quote_version_reset_error_prefix: 'An error occurred while resetting quote version',
  quote_version_reset_in_progress: 'Resetting quote version...',
  quote_version_reset_success: 'Quote version has been successfully reset.',
  quotes_from_vendors: 'Quotes from Vendors',
  quotes_to_customers: 'Quotes to Customers',
  reattach_vendor_quotes_in_progress: 'Reattaching vendor quotes...',
  reattach_vendor_quotes_error: 'An error occurred while reattaching vendor quotes',
  reattach_vendor_quotes_success: 'Vendor quotes have been successfully reattached.',
  recover_password: 'recover password',
  region_label: 'Region',
  reject_parts_confirmation_message: 'Are you sure you want to continue and reject {{ nParts }} part(s)?',
  release: 'release',
  release_without_email_label: 'release-no email',
  remove_from_bom: 'remove from job',
  remove_from_project: 'Remove from Project',
  remove_export_field_mapping_confirm_message: 'Are you sure you want to remove the export field mapping?',
  remove_log_confirm_message: 'Are you sure you want to remove the log?',
  remove_task_type_confirm_message: 'Are you sure you want to remove the task type?',
  'requested-by-customer': 'requested',
  resend_email_verification_link: 'resend email verification link',
  reset_pricing_in_progress: 'Resetting pricing...',
  reset_pricing_success: 'Pricing has been successfully reset.',
  resetting_purchase_cost_error_prefix: 'An error occurred while resetting purchase cost',
  restore_deleted_contact_confirmation_message: 'A contact was already set up for <b>{{ email }}</b> but is currently deleted. Do you want to restore it?',
  restore_deleted_records_error_prefix: 'An error occurred while restoring deleted records',
  restore_deleted_records_in_progress: 'Restoring deleted records...',
  restore_deleted_records_success: 'Deleted records have been successfully restored.',
  return_to_quote_cancel_label: 'return to quote',
  revoking_contact_access: 'Revoking contact\'s access...',
  same_base_parts: 'All parts with the same base',
  save_address_in_progress: 'Saving address...',
  save_error_prefix: 'An error occurred while saving',
  save_in_progress: 'Saving...',
  save_part_error_prefix: 'An error occurred while saving the part',
  save_part_in_progress: 'Saving part...',
  save_parts_in_progress: 'Saving parts...',
  save_project_in_progress: 'Saving project...',
  save_success: 'Saved successfully.',
  search_quote_in_progress: 'Searching quote...',
  search_quote_success: 'Quote found.',
  select_bom_first: 'Please select a job first.',
  select_manufacturer: 'Select a Manufacturer',
  select_message_ccs: 'Please select message CCs',
  select_option: 'Please select an option',
  select_options: 'Please select options',
  select_project: 'Select a Project',
  select_vendor: 'Select a Vendor',
  selected_part: 'Selected part only',
  send_quote_to_vendor_error_prefix: 'An error occurred while sending quote to vendor',
  send_quote_to_vendor_in_progress: 'Sending quote to vendor...',
  send_quote_to_vendor_success: 'Quote has been succesfully sent to vendor.',
  set_location_shipment_type_error: 'An error occurred while setting parts shipment type and location.',
  set_location_shipment_type_in_progress: 'Setting parts shipment type and location...',
  set_location_shipment_type_success: 'Parts shipment type and location have been successfully set.',
  'shipped-partial': 'shipped partial',
  'shipped-complete': 'shipped complete',
  shipment_type_alternate_code: 'alternate code',
  shipment_type_code: 'code',
  shipment_type_default_for: 'default for',
  shipment_type_default_location: 'default location',
  shipment_type_exceptions: 'exceptions',
  shipment_type_name: 'name',
  shipping_cost_label: 'shipping cost',
  std_cost: 'standard cost',
  submit_order_confirm_msg: 'Please confirm that you want to submit the order.',
  submit_order_label: 'submit order',
  submit_order_loading_msg: 'Submitting the order...',
  submit_order_success_msg: 'Order submitted successfully',
  terms_conditions_label: 'Terms & Conditions',
  unavailable_quotation_note: 'No note available.',
  unavailable_suggestions: 'No lead time suggestions available.',
  undiscardable_item: 'This item is non-cancelable and non-returnable.',
  undiscardable_item_note: 'undiscardable item note',
  update__addressType__address_label: 'edit {{ addressType }} address',
  update_contacts: 'Update Contacts',
  update_contact_now: 'Update Contact Now',
  update_contacts_now: 'Update Contacts Now',
  update_price_now: 'Update Price Now',
  update_quantity_button_label: 'Update Quantity Now',
  url_label: 'URL',
  use_existing_vendor_quote_button_label: 'Use Existing Vendor Quote',
  valid_since: 'Valid Since',
  valid_until: 'Valid Until',
  validity_dates: 'validity dates',
  value: 'value',
  vendor: 'vendor',
  vendor_customer_part_number_label: 'v',
  vendor_order_label: 'vendor order',
  vendor_quote: 'vendor quote',
  vertical_market_label: 'Vertical Market',
  zip_code_label: 'Zip/Postal Code',
  warehouse_location: 'Warehouse Location',

  // Info messages
  info_component_accept_cost_uom: 'You\'re viewing the vendor\'s UOM "{{ cost_uom }}" instead of yours "{{ uom }}". Click to accept it and make it the default UOM.',
  info_convert_part_uom: 'UOM does not match your saved cost UOM. Would you like to convert it?',

  // Loading messages
  loading_lead_time_suggestions: 'Loading lead time suggestions...',
  loading_reorder: 'Reordering...',
  loading_save_component: 'Saving component...',
  loading_save_price: 'Saving price...',
  loading_sync_partfiniti_parts: 'Synchronizing Partfiniti parts...',
  loading_update_uom: 'Updating UOM...',
  loading_upload_files: 'Uploading files...',

  // Loading message prefixes
  loading_prefix_restore: 'Restoring',

  // Success messages
  success_email_verification_link_sent: 'If this is an account that needs verification, you\'ll get the link in your inbox right away.',
  success_importing_file: 'File has been successfully imported.',
  success_recover_password_email_sent: 'If this is a valid account, you\'ll get the password recovery link in your inbox right away.',
  success_save_component: 'Component has been successfully saved.',
  success_save_part: 'Part has been successfully saved.',
  success_suffix_restored: 'has been successfully restored',
  success_sync_partfiniti_parts: 'Partfiniti parts have been succesfully synchronized.',
  success_update_uom: 'UOM has been successfully updated.',

  // Error messages
  error_access_denied: 'Access denied.',
  error_occurred: 'An error occurred',
  error_amount_below_min_order_threshold: 'The total amount is below the company minimum order threshold.',
  error_attach_file_to_bom_prefix: 'An error occurred while attaching the file',
  error_counting_company_parts_prefix: 'An error occurred while counting the company parts',
  error_detach_only_attached_quotes: 'Only attached quotes can be detached.',
  error_part_class_too_long: 'Part class cannot exceed 155 characters.',
  error_email_too_long: 'Email cannot exceed 255 characters.',
  error_empty_address_city: 'City cannot be empty.',
  error_empty_address_country: 'Country cannot be empty.',
  error_empty_address_line1: 'Street address cannot be empty.',
  error_empty_address_name: 'Location name cannot be empty.',
  error_empty_address_state: 'State cannot be empty.',
  error_empty_address_zip: 'Zip/Postal code cannot be empty.',
  error_empty_apply_to: '"Apply to" must be selected.',
  error_empty_attach_to: '"Attach to" must be selected.',
  error_empty_bom: 'Please select a job.',
  error_empty_bom_detail_customer_name: 'Customer name cannot be empty.',
  error_empty_bom_name: 'Job name cannot be empty.',
  error_empty_bom_price_decimals: 'BoM price decimals cannot be empty.',
  error_empty__contactType: '{{ contactType }} cannot be empty.',
  error_empty_city: 'City cannot be empty.',
  error_empty_close_confidence: 'Close confidence cannot be empty.',
  error_empty_close_date: 'Estimated close date cannot be empty.',
  error_empty_company: 'Please select a company.',
  error_empty_company_uom_conversion_from_uom: 'Base UOM cannot be empty.',
  error_empty_company_uom_conversion_multiplier: 'Multiplier cannot be empty.',
  error_empty_company_uom_conversion_to_uom: 'Alternate UOM cannot be empty.',
  error_empty_component_name: 'Component name cannot be empty.',
  error_empty_contact_id: 'Please select a contact.',
  error_empty_contact_group: 'Contact group cannot be empty.',
  error_empty_corporate_bom_status_name: 'Job status name cannot be empty',
  error_empty_corporate_bom_status_priority: 'Job status priority cannot be empty',
  error_empty_corporate_discipline_name: 'Discipline name cannot be empty',
  error_empty_corporate_location: 'Location cannot be empty.',
  error_empty_corporate_part_field_name: 'Company part field name cannot be empty.',
  error_empty_corporate_part_field_value: 'Company part field value cannot be empty.',
  error_empty_corporate_part_note_content: 'Note cannot be empty.',
  error_empty_corporate_part_uom: 'Base UOM cannot be empty.',
  error_empty_corporate_region: 'Region cannot be empty.',
  error_empty_corporate_user: 'User cannot be empty.',
  error_empty_cost: 'Cost cannot be empty.',
  error_empty_cost_discount: 'Cost discount cannot be empty.',
  error_empty_costing_profile_name: 'Costing profile name cannot be empty.',
  error_empty_country: 'Country cannot be empty.',
  error_empty_custom_identifier: 'Identifier cannot be empty.',
  error_empty_customer: 'Customer cannot be empty.',
  error_empty_customer_contact_group: 'Customer cannot be empty.',
  error_empty_customer_part_number: 'Customer part number cannot be empty.',
  error_empty_description: 'Description cannot be empty.',
  error_empty_discount: 'Discount cannot be empty.',
  error_empty_document_name: 'Document name cannot be empty.',
  error_empty_duration_for_activity_date: 'Duration for activity date: {{ activityDate }} cannot be empty.',
  error_empty_duration_total: 'Total sum of durations must be greater than 0.',
  error_empty_element_name: 'Element name cannot be empty.',
  error_empty_element_template: 'Element template cannot be empty.',
  error_empty_email: 'Please enter the email address.',
  error_empty_estimated_close_date: 'Estimated close date cannot be empty.',
  error_empty_estimated_value: 'Estimated value cannot be empty.',
  error_empty_external_customer_number: 'Customer number cannot be empty.',
  error_empty_external_user_id: 'External user ID cannot be empty.',
  error_empty_fixed_price: 'Fixed price cannot be empty.',
  error_empty_freight_note: 'Freight note cannot be empty.',
  error_empty_global_uom_conversion_from_uom: 'Base UOM cannot be empty.',
  error_empty_global_uom_conversion_multiplier: 'Multiplier cannot be empty.',
  error_empty_global_uom_conversion_to_uom: 'Alternate UOM cannot be empty.',
  error_empty_integration_setting_folder_id: 'Folder ID cannot be empty.',
  error_empty_integration_setting_get_order_url: 'Get order URL cannot be empty.',
  error_empty_integration_setting_integration_type: 'Integration type cannot be empty.',
  error_empty_integration_setting_order_base_url: 'Order base URL cannot be empty.',
  error_empty_integration_setting_password: 'Password cannot be empty.',
  error_empty_integration_setting_pending_order_base_url: 'Pending order base URL cannot be empty.',
  error_empty_integration_setting_submit_pending_order_url: 'Submit order pending URL cannot be empty.',
  error_empty_integration_setting_url: 'URL cannot be empty.',
  error_empty_integration_setting_username: 'User name cannot be empty.',
  error_empty_job_name: 'Job name cannot be empty.',
  error_empty_location_id: 'Quoted location cannot be empty.',
  error_empty_location_name: 'Location name cannot be empty.',
  error_empty_location_street: 'Street name cannot be empty.',
  error_empty_main_contact_group: 'Please select the main contact company.',
  error_empty_manufacturer: 'Manufacturer cannot be empty.',
  error_empty_manufacturer_order_increment: 'Vendor order increment cannot be empty.',
  error_empty_note_content: 'Note cannot be empty.',
  error_empty_operator: 'The operator cannot be empty.',
  error_empty_part: 'Part cannot be empty.',
  error_empty_part_description: 'Part description cannot be empty.',
  error_empty_part_note_content: 'Quote part note cannot be empty.',
  error_empty_part_number: 'Part number cannot be empty.',
  error_empty_part_uom_conversion_from_uom: 'Base UOM cannot be empty.',
  error_empty_part_uom_conversion_multiplier: 'Multiplier cannot be empty.',
  error_empty_part_uom_conversion_to_uom: 'Alternate UOM cannot be empty.',
  error_empty_partfiniti_part_number: 'Partfiniti part number cannot be empty.',
  error_empty_password: 'Please enter the password.',
  error_empty_pricing_group: 'Pricing group cannot be empty.',
  error_empty_pricing_options: 'Please choose at least one of the following: prices, margins, costs.',
  error_empty_pricing_profile_name: 'Pricing profile name cannot be empty.',
  error_empty_pricing_types: 'You must check at least one item.',
  error_empty_project: 'Please select a project.',
  error_empty_project_id: 'Please select a project.',
  error_empty_project_name: 'Project name cannot be empty.',
  error_empty_purchase_cost: 'Purchase cost cannot be empty.',
  error_empty_purchase_order_number: 'Purchase order number cannot be empty.',
  error_empty_quantity: 'Quantity cannot be empty.',
  error_empty_quote__contactType: '{{ contactType }} cannot be empty.',
  error_empty_quote_contact: 'Please select a quote contact.',
  error_empty_region_name: 'Region name cannot be empty.',
  error_empty_sales_location_id: 'Sales location ID cannot be empty.',
  error_empty_sales_order_increment: 'Sales order increment cannot be empty.',
  error_empty_selected_parts: 'No parts selected.',
  error_empty_selected_quotes: 'No quotes selected.',
  error_empty_shipment_type_code: 'Code cannot be empty.',
  error_empty_shipment_type_exception_field: 'You must select a field for each exception.',
  error_empty_shipment_type_exception_value: 'You must specify a value for each exception.',
  error_empty_shipment_type_name: 'Name cannot be empty.',
  error_empty_start_date: 'Start date cannot be empty.',
  error_empty_status_lost_note: 'Please specify the reason for losing the quote.',
  error_empty_support_level_name: 'Support level name cannot be empty.',
  error_empty_support_level_priority: 'Support level priority cannot be empty.',
  error_empty_support_request_status_name: 'Request status name cannot be empty.',
  error_empty_support_request_status_priority: 'Request status priority cannot be empty.',
  error_empty_support_request_type_name: 'Request type name cannot be empty.',
  error_empty_uom: 'UOM cannot be empty.',
  error_empty_uom_corporate_translation_from: 'Import UOM cannot be empty.',
  error_empty_uom_corporate_translation_to: 'Costing UOM cannot be empty.',
  error_empty_user: 'Please select a user.',
  error_empty__uomType__uom: '{{ uomType }} UOM cannot be empty.',
  error_empty_valid_until: 'Valid until date cannot be empty.',
  error_fill_one_field: 'Please fill in at least one field.',
  error_generating_datasheet_archive_prefix: 'An error occurred while downloading the datasheets archive',
  error_generating_export_file_prefix: 'An error occurred while generating the export file',
  error_importing_file_prefix: 'An error occurred while importing file',
  error_invalid_bom_status_priority: 'Job status priority must be a whole number greater than 0.',
  error_invalid_cost_discount: 'The cost discount must be a number greater than 0.',
  error_invalid_decimals_number: 'The number of decimals must be between 1 and 5.',
  error_invalid_discount_value: 'The discount value must be a number greater than or equal to 0.',
  error_invalid_external_customer_number: 'Customer number must be 6 characters long, digits only.',
  error_invalid_price: 'Invalid price.',
  error_invalid_purchase_cost: 'Invalid purchase cost.',
  error_invalid_quantity: 'Invalid quantity.',
  error_invalid_reorder: 'Invalid reordering.',
  error_invalid_start_date_time: 'Start date and time are invalid',
  error_invalid_support_level_priority: 'Support level priority must be a whole number greater than 0.',
  error_invalid_support_request_status_priority: 'Request status priority must be a whole number greater than 0.',
  error_invalid_finish_date_time: 'Finish date and time are invalid',
  error_invalid_type: 'Invalid type.',
  error_line_number_must_be_number: 'line number must be a number',
  error_line_number_out_of_range: 'line number out of range',
  error_loading_customer_data_prefix: 'An error occurred while loading customer data',
  error_loading_vendor_data_prefix: 'An error occurred while loading vendor data',
  error_margin_warning_range_order: 'Minimum margin threshold must be lower than maximum margin threshold.',
  error_no_parts_provided: 'No part provided for data sheets export.',
  error_part_access_denied: 'Part is not available.',
  error_reattach_only_detached_quotes: 'Only detached quotes can be reattached.',
  error_recaptcha_not_solved: 'Please solve the reCAPTCHA.',
  error_resource_not_found: 'Resource not found.',
  error_save_component_prefix: 'An error occurred while saving the component',
  error_server_error: 'Server error.',
  error_start_must_be_before_finish: 'Start time must be before finish time',
  error_submit_recaptcha_response: 'An error occurred while submitting your reCAPTCHA response. Please make sure your network connectivity works, then try again.',
  error_terms_and_conditions_not_accepted: 'You must accept the terms and conditions.',
  error_timeout_error: 'Timeout error.',
  error_validation_error: 'Validation error.',

  // Error message prefixes
  error_prefix_get_lead_time_suggestions: 'An error occurred while loading the lead time suggestions',
  error_prefix_get_uoms_and_pricing: 'An error occurred while fetching UOM and pricing data',
  error_prefix_load_data: 'An error occurred while loading data',
  error_prefix_reorder_bom_lines: 'An error occurred while reordering',
  error_prefix_restore: 'An error occurred while restoring',
  error_prefix_sync_partfiniti_parts: 'An error occurred while synchronizing Partfiniti parts',
  error_prefix_update_uom: 'An error occurred while updating UOM',
  error_prefix_validate_quote_before_import: 'An error occurred while validating the quote',

  // Question messages
  question_forgot_password: 'Forgot password?',
  question_no_account_yet: 'Don’t have an account yet?',

  // Warning messages
  warning_associated_partfiniti_part_not_verified: 'The associated Partfiniti part is not verified.',
  warning_associated_partfiniti_part__partfinitiPartLabel__not_verified: 'The associated Partfiniti part "{{ partfinitiPartLabel }}" is not verified.',
  warning_bom_part_uom_will_be_changed: 'The part\'s unit of measure "{{ uom }}" will be changed. Please update the quantity accordingly.',
  warning_component_uom_will_be_changed: 'The component\'s unit of measure "{{ uom }}" will be changed. Please update the quantity accordingly.',
  warning_component_without_part_for_order: 'All lines must have a part number assigned. Click cancel to return and assign part numbers or click confirm to create an order and omit lines without part numbers.',
  warning_fixed_price_uom_will_be_changed: 'UOM will be changed from "{{ oldUom }}" to "{{ newUom }}". Please update the cost / price accordingly.',
  warning_get_cost_not_allowed_if_bom_not_selected: 'Cannot get cost from vendor if no job is selected.',
  warning_new_alternate_part_row_already_created: 'Alternate part row has been already opened.',
  warning_part_cost_edit_not_allowed_switch_to_view_by_part: 'Part costing edit is not allowed in this view. Please switch to "View by Part Number" in order to make changes.',
  warning_part_price_edit_not_allowed_switch_to_view_by_part: 'Part pricing edit is not allowed in this view. Please switch to "View by Part Number" in order to make changes.',
  warning_part_set_up_edit_not_allowed_switch_to_view_by_part: 'Company part edit is not allowed in this view. Please switch to "View by Part Number" in order to make changes.',
  warning_pricing_profile_update_part: 'Fixed price/cost already exists for the following parts: <b>{{partNumbers}}</b>. <br>Do you want to update them?',
  warning_quote_parts_without_pricing_for_order: 'Order will include lines with no cost. Please confirm that you want to create the order.',
  warning_submit_order_parts_without_pricing: 'The order contains order parts with no cost.',
  warning_update_quantity_per_element_not_allowed_if_bom_not_selected: 'Updating quantity per element is not allowed if no job is selected.',
  warning_update_quote_contact: 'The customer contact you are quoting is missing some important information. Please make sure all the addresses have been assigned.',
  warning_validation_cost: 'Old/expired cost',
  warning_validation_gpm: 'GPM out of range',
  warning_validation_quantity_uom: 'Check quantity vs UOM',
  warning_validation_order_increment: 'Invalid increment',
  warning_validation_moq: 'Quantity below MOQ',
  warning_vendor_quote_already_exists: 'This vendor quote already exists.',

  // Checkbox messages
  attach_as_vendor_quote_message: 'Attach as vendor quote',
  terms_conditions_agreement_message: 'I agree to the Terms and Conditions',

  // Month labels
  january: 'january',
  february: 'february',
  march: 'march',
  april: 'april',
  may: 'may',
  june: 'june',
  july: 'july',
  august: 'august',
  september: 'september',
  october: 'october',
  november: 'november',
  december: 'december',
};
/* eslint-enable max-len */

/**
 * Translator.
 *
 * @class
 */
function Translator() {
  /**
   * Get translation.
   *
   * @param  {string} key
   * @param  {object} [params]
   * @param  {object} [opts]
   * @return {string}
   */
  this.get = function (key, params = {}, opts = {}) {
    const options = {
      force: true,
      ...opts,
    };

    let translation = '';

    if (_.isUndefined(staticSelf.translations[key]) && options.force) {
      // eslint-disable-next-line no-console, prefer-template
      console.warn('Translation for key "' + key + '" not found.');

      translation = key.replace(/_/g, ' ');
    } else {
      translation = _.template(staticSelf.translations[key])(params);
    }

    return translation;
  };

  /**
   * Get translation with first word capitalized.
   *
   * @param  {string} key
   * @param  {object} [params]
   * @return {string}
   */
  this.getCapitalized = function (key, params = {}) {
    return this.get(key, params).capitalize();
  };

  /**
   * Get translation as title (capitalize words).
   *
   * @param  {string} key
   * @param  {object} [params]
   * @return {string}
   */
  this.getTitle = function (key, params = {}) {
    return this.get(key, params).capitalizeWords();
  };

  /**
   * Alias for `getTitle`.
   *
   * @param  {string} key
   * @param  {object} [params]
   * @return {string}
   */
  this.title = function (key, params = {}) {
    return this.getTitle(key, params);
  };

  /**
   * Get translation with all characters uppercased.
   *
   * @param  {string} key
   * @param  {object} [params]
   * @return {string}
   */
  this.getUppercased = function (key, params = {}) {
    return this.get(key, params).toUpperCase();
  };
}

export const translator = new Translator();

export default Translator;
