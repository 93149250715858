import NumberHelper from 'js/helpers/number-helper';

const PriceHelper = {};

/**
 * @const
 */
PriceHelper.PRECISION = 0.00001;

/**
 * @const
 */
PriceHelper.GPM_PRECISION = 0.0001;

/**
 * @const
 */
PriceHelper.PRICING_TYPE_BASE = 'base';

/**
 * @const
 */
PriceHelper.PRICING_TYPE_ADDER = 'adder';

/**
 * @const
 */
PriceHelper.PRICING_TYPE_TOTAL = 'total';

/**
 * @const
 */
PriceHelper.COMPARE_RESULT_EQUAL = 0;

/**
 * @const
 */
PriceHelper.COMPARE_RESULT_GREATER = 1;

/**
 * @const
 */
PriceHelper.COMPARE_RESULT_SMALLER = -1;

/**
 * Get pricing value as float if non-empty, otherwise get placeholder.
 *
 * @param  {*} pricingValue
 * @param  {*} placeholder
 * @return {*}
 */
PriceHelper.get = function(pricingValue, placeholder = null) {
  return PriceHelper.isNonEmpty(pricingValue) ?
    parseFloat(pricingValue) :
    placeholder;
};

/**
 * Check whether the first price is equal to the second one.
 *
 * @param  {number}  firstPrice
 * @param  {number}  secondPrice
 * @return {boolean}
 */
PriceHelper.areEqual = function(firstPrice, secondPrice) {
  return NumberHelper.areEqual(
    firstPrice,
    secondPrice,
    PriceHelper.PRECISION,
  );
};

/**
 * Check whether the first GPM is equal to the second one.
 *
 * @param  {number}  firstGpm
 * @param  {number}  secondGpm
 * @return {boolean}
 */
PriceHelper.areGpmsEqual = function(firstGpm, secondGpm) {
  return NumberHelper.areEqual(
    firstGpm,
    secondGpm,
    PriceHelper.GPM_PRECISION,
  );
};

/**
 * Compute GPM.
 *
 * @param  {number}  cost   Cost.
 * @param  {number}  price  Price.
 * @param  {object}  [opts] Options.
 * @return {?number}        Computed gross profit margin (between 0 and 1).
 */
PriceHelper.computeGpm = function(cost, price, opts = {}) {
  const options = {
    precision: null, // Number of decimals
    ...opts,
  };

  const processedCost = parseFloat(cost);
  const processedPrice = parseFloat(price);

  if (!processedCost || !processedPrice) {
    return null;
  }

  let gpm = 1 - processedCost / processedPrice;

  if (options.precision > 0) {
    gpm = NumberHelper.round(gpm, options.precision);
  }

  return gpm;
};

/**
 * Compute price.
 *
 * @param  {number}  cost
 * @param  {number}  gpm
 * @return {?number}
 */
PriceHelper.computePrice = function(cost, gpm) {
  if (!cost || 1 === +gpm) {
    return null;
  }

  return cost / (1 - gpm);
};

/**
 * Compare two prices.
 *
 * @param  {number}  firstPrice
 * @param  {number}  secondPrice
 * @return {?number}
 */
PriceHelper.compare = function(firstPrice, secondPrice) {
  return NumberHelper.compare(
    firstPrice,
    secondPrice,
    PriceHelper.PRECISION,
  );
};

/**
 * Compare two gpms.
 *
 * @param  {number}  firstGpm
 * @param  {number}  secondGpm
 * @return {?number}
 */
PriceHelper.compareGpm = function(firstGpm, secondGpm) {
  return NumberHelper.compare(
    firstGpm,
    secondGpm,
    PriceHelper.GPM_PRECISION,
  );
};

/**
 * Determine whether pricing value is empty.
 *
 * @param  {*}       pricingValue
 * @return {boolean}
 */
PriceHelper.isEmpty = function(pricingValue) {
  return !(pricingValue > 0);
};

/**
 * Determine whether pricing value is non-empty.
 *
 * @param  {*}       pricingValue
 * @return {boolean}
 */
PriceHelper.isNonEmpty = function(pricingValue) {
  return pricingValue > 0;
};

export default PriceHelper;
