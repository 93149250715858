import BaseHelper from 'js/base_v3/helpers/helper';

/**
 * Part URL Helper.
 *
 * @class
 * @extends BaseHelper
 *
 * @param {object} [options]
 */
function PartUrlHelper(options) {
  BaseHelper.call(this, options);

  /**
   * Get part URL.
   *
   * @param  {object} part
   * @return {string}
   */
  this.getUrl = function(part) {
    const manufacturerUrlName = encodeURIComponent(part.manufacturer_url_name);

    const partNumber = encodeURIComponent(
      part.number.replace(/ /g, '_').replace(/#/g, '%23'),
    );

    return `/part_v2?number=${partNumber}&manufacturer=${manufacturerUrlName}`;
  };

  // Initialize
  this._init();
}

export const partUrlHelper = new PartUrlHelper();

export default PartUrlHelper;
