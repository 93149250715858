import BaseSelect2Field from 'js/base_v2/select2-field';
import Ajax from 'js/components/ajax';

const staticSelf = BaseSelect2AutocompleteField;

/**
 * @const
 */
staticSelf.PAGE_SIZE = 30;

/**
 * Base Select2 Autocomplete Field.
 *
 * @class
 * @abstract
 * @extends BaseSelect2Field
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     [options]
 */
function BaseSelect2AutocompleteField(fieldCt, fieldEl, options) {
  BaseSelect2Field.call(this, fieldCt, fieldEl, options);
  const parent = this.clone();

  /**
   * @prop {boolean}
   */
  this.multiple = null;

  /**
   * @prop {boolean}
   */
  this.clearClicked = false;

  /**
   * @prop {boolean}
   */
  this.triggerChange = true;

  /**
   * @prop {object[]}
   */
  this.data = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      valueInitialization: BaseSelect2Field.VALUE_INITIALIZATION_AFTER_SELECT2,
      entityName: 'record',
      entitiesName: 'records',
      entityIdentifierField: 'id',
      selectedRecords: [],
      autocompleteParams: {},
      autoSelect: false,
      autoSelectFirstOption: false,
      showNoValueOption: false,
      noValueOptionValue: '(empty)',
      noValueOptionLabel: this.translator.getTitle('no_value'),
      search: true,
      select2: {
        ajax: {
          url: '',
          delay: 250,
          data: (params) => this.select2Data(params),
          // eslint-disable-next-line max-len
          processResults: (data, params) => this.select2ProcessResults(data, params),
          cache: true,
        },
        minimumInputLength: 0,
        templateResult: (repo) => this.select2TemplateResult(repo),
        templateSelection: (repo) => this.select2TemplateSelection(repo),
        escapeMarkup: (markup) => markup,
      },
      beforeChange: undefined,
      onChange: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    if (_.isArray(this.data)) {
      this.extendOptions({
        select2: {
          data: this.data,
          ajax: null,
        },
      });
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initFields = function() {
    parent.initFields.call(this);

    this.multiple = this.fieldEl.prop('multiple');

    return this.initAutocomplete();
  };

  /**
   * Initialize autocomplete.
   *
   * @return {BaseSelect2AutocompleteField}
   */
  this.initAutocomplete = function() {
    this.fieldEl.select2(this.options.select2);

    return this;
  };

  /**
   * Set field initial value.
   *
   * @return {BaseSelect2AutocompleteField}
   */
  this.setInitialValue = function() {
    this.setSelectData([]);

    this.fieldEl.data('is_new', false);

    if (this.options.selectedRecords?.length > 0) {
      this.setRecord(this.options.selectedRecords, false);
    } else if (_.isObject(this.options.initialValue)) {
      this.setRecord(this.options.initialValue, false);
    } else if (this.options.autoSelect || this.options.autoSelectFirstOption) {
      this.tryAutoSelect();
    } else {
      this.actionSpan.hide();
    }

    return this;
  };

  /**
   * Auto select record if there is exactly one choice.
   *
   * @return {BaseSelect2AutocompleteField}
   */
  this.tryAutoSelect = function() {
    if (_.isObject(this.getRecord())) {
      return this;
    }

    const { url } = this.options.select2.ajax;

    const data = Object.extend(this.select2Data({ term: '', page: 1 }), {
      pageSize: 2,
    });

    Ajax.get(url, data, (response) => {
      const records = this.getRecordsFromResponse(response);

      if (1 !== records.length &&
        !(records.length > 0 && this.options.autoSelectFirstOption)
      ) {
        return;
      }

      this.setRecord(records[0]);
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.onOpening = function(event) {
    if (this.clearClicked) {
      event.preventDefault();

      this.clearClicked = false;
    }
  };

  /**
   * @inheritDoc
   */
  this.onUnselect = function() {
    this.clearClicked = true;

    if (!this.multiple) {
      this.fieldEl.append(
        $('<option>')
          .prop('selected', true)
          .prop('disabled', true),
      );
    }
  };

  /**
   * @inheritDoc
   */
  this.onChange = function() {
    if (this.triggerChange && _.isFunction(this.options.beforeChange)) {
      this.options.beforeChange(this.fieldEl, this.getSelect2Data());
    }

    if (!this.fieldEl.data('is_new')) {
      this.setSelectData(this.extractSelectDataFromSelect2Data(
        this.getSelect2Data(),
      ));
    } else {
      this.fieldEl.data('is_new', false);
    }

    if (this.isEmpty()) {
      if (!this.multiple) {
        $('option:enabled', this.fieldEl).remove();
      }

      this.actionSpan.hide();
    } else {
      if (!this.multiple) {
        let recordValue = this.getRecordValue(this.getRecord());

        if (_.isString(recordValue)) {
          recordValue = recordValue.replace(/"/g, '\\"');
        }

        $(`:not(option[value="${recordValue}"])`, this.fieldEl).remove();
      }

      this.actionSpan.show();
    }

    if (this.triggerChange && _.isFunction(this.options.onChange)) {
      this.options.onChange(this.fieldEl);
    }

    this.triggerChange = true;

    return false;
  };

  /**
   * Get Select2 AJAX data.
   *
   * @param  {object} params
   * @return {object}
   */
  this.select2Data = function(params) {
    return this.options.autocompleteParams.extend({
      q: params.term,
      page: params.page || 1,
      pageSize: params.pageSize || staticSelf.PAGE_SIZE,
    });
  };

  /**
   * Process Select2 AJAX results.
   *
   * @param  {object} data
   * @param  {object} params
   * @return {object}
   */
  this.select2ProcessResults = function(data, params) {
    const page = params.page || 1;

    const results = this.processResults(
      Object.values(this.getRecordsFromResponse(data)),
      params,
    );

    const recordsCount = this.getRecordsCountFromResponse(data);

    return {
      results,
      pagination: {
        more: (page * staticSelf.PAGE_SIZE) < recordsCount,
      },
    };
  };

  /**
   * Process given results.
   *
   * @param  {object[]} results
   * @param  {object}   params
   * @return {object[]}
   */
  this.processResults = function(results, params) {
    let processedResults = results;

    _.each(results, function(r, i) {
      processedResults[i].id = this.getRecordIdentifier(r);
      processedResults[i].original_record = Object.clone(results[i]);
    }, this);

    if (this.options.showNoValueOption && _.isEmpty(params.term)) {
      processedResults = [{
        id: this.options.noValueOptionValue,
        name: this.options.noValueOptionLabel,
      }].concat(processedResults);
    }

    return processedResults;
  };

  /**
   * Get Select2 result template.
   *
   * @param  {object} repo
   * @return {string}
   */
  this.select2TemplateResult = function(repo) {
    return repo.text || this.getRepoLabel(repo);
  };

  /**
   * Get Select2 selection template.
   *
   * @param  {object} repo
   * @return {string}
   */
  this.select2TemplateSelection = function(repo) {
    return repo.text || this.getRepoLabel(repo);
  };

  /**
   * Get repo label.
   *
   * @param  {object} repo
   * @return {string}
   */
  this.getRepoLabel = function(repo) {
    return this.getRecordLabel(repo);
  };

  /**
   * Set selected record(s).
   *
   * @param  {object|object[]}              records
   * @param  {boolean}                      triggerChange
   * @return {BaseSelect2AutocompleteField}
   */
  this.setRecord = function(records, triggerChange) {
    this.triggerChange = 'undefined' !== typeof triggerChange ?
      triggerChange :
      this.triggerChange;

    this.fieldEl.html('');

    if (!records || 0 === records.length) {
      if (!this.fieldEl.prop('multiple')) {
        this.fieldEl.append('<option selected disabled></option>');
      }

      this.fieldEl.val('');

      this.setSelectData([]);

      this.fieldEl.trigger('change');

      return this;
    }

    if (!_.isArray(records)) {
      // eslint-disable-next-line no-param-reassign
      records = [records];
    }

    // eslint-disable-next-line no-param-reassign
    records = records.map(
      (record) => (_.isFunction(record.getData) ? record.getData() : record),
    );

    Object.values(records).forEach((record) => {
      if ('undefined' === typeof record.original_record) {
        // eslint-disable-next-line no-param-reassign
        record.original_record = Object.clone(record);
      }

      let recordValue = this.getRecordValue(record);

      if (_.isString(recordValue)) {
        recordValue = recordValue.replace(/"/g, '\\"');
      }

      this.fieldEl.append(
        $('<option>')
          .attr('value', recordValue)
          .prop('selected', true)
          .data('record', record.original_record)
          .html(this.getRecordLabel(record)),
      );
    });

    this.setSelectData(records);

    this.fieldEl.data('is_new', true);

    this.fieldEl.trigger('change');

    return this;
  };

  /**
   * @inheritDoc
   */
  this.setValue = function(value, triggerChange) {
    return this.setRecord(value, triggerChange);
  };

  /**
   * Get records from response.
   *
   * @param  {object}        response
   * @return {object[]|null}
   */
  this.getRecordsFromResponse = function(response) {
    if (_.isArray(response[this.options.entitiesName])) {
      return response[this.options.entitiesName];
    }

    return response.data;
  };

  /**
   * Get records count from response.
   *
   * @param  {object} response
   * @return {number}
   */
  this.getRecordsCountFromResponse = function(response) {
    if (Object.prototype.hasOwnProperty.call(response, 'total_count')) {
      return response.total_count;
    }

    return response.recordsFiltered;
  };

  /**
   * Get selected record(s).
   *
   * @return {object|object[]}
   */
  this.getRecord = function() {
    const selectData = this.getSelectData();

    if (0 === selectData.length) {
      return null;
    }

    const selectedRecords = [];

    Object.values(selectData).forEach((item) => {
      if (item.disabled) {
        // Nothing to do
      } else if (item.original_record) {
        selectedRecords.push(item.original_record);
      } else {
        selectedRecords.push(item);
      }
    });

    if (0 === selectedRecords.length) {
      return null;
    }

    return !this.multiple ? selectedRecords[0] : selectedRecords;
  };

  /**
   * @inheritDoc
   */
  this.getValue = function() {
    return this.getRecord();
  };

  /**
   * Get Select2 selection data.
   *
   * @return {object[]}
   */
  this.getSelect2Data = function() {
    return this.fieldEl.select2('data') || [];
  };

  /**
   * Get selection data.
   *
   * @return {object[]}
   */
  this.getSelectData = function() {
    return this.fieldEl.data('record') || [];
  };

  /**
   * Extract selection data from Select2 selection data.
   *
   * @param  {object[]} select2Data
   * @return {object[]}
   */
  this.extractSelectDataFromSelect2Data = function(select2Data) {
    const selectData = [];

    _.each(select2Data, (item) => {
      const option = $(item.element);

      if (option.length > 0 && _.isObject(option.data('record'))) {
        selectData.push(option.data('record'));
      } else if (_.isObject(item.original_record)) {
        selectData.push(item.original_record);
      } else {
        selectData.push(item);
      }
    });

    return selectData;
  };

  /**
   * Set selection data.
   *
   * @param  {object[]}                     records
   * @return {BaseSelect2AutocompleteField}
   */
  this.setSelectData = function(records) {
    this.fieldEl.data('record', records);

    return this;
  };

  /**
   * Check whether the field is empty (no value selected).
   *
   * @return {boolean}
   */
  this.isEmpty = function() {
    const record = this.getRecord();

    return !record || (_.isArray(record) && 0 === record.length);
  };

  /**
   * Get record value.
   *
   * @param  {object} record
   * @return {string}
   */
  this.getRecordValue = function(record) {
    return this.getRecordIdentifier(record) || record.value;
  };

  /**
   * Get record identifier.
   *
   * @param  {object} record
   * @return {*}
   */
  this.getRecordIdentifier = function(record) {
    return record[this.options.entityIdentifierField];
  };

  /**
   * Get record label.
   *
   * @param  {object} record
   * @return {string}
   */
  this.getRecordLabel = function(record) {
    return record.name || record.text || record.label;
  };
}

export default BaseSelect2AutocompleteField;
