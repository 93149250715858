import BaseExtension from 'js/base_v3/extensions/extension';
/**
 * List Extension.
 *
 * @class
 * @extends BaseExtension
 *
 * @param {object}  parentComponent
 * @param {object}  [options]
 */
function ListExtension(parentComponent, options) {
    BaseExtension.call(this, parentComponent, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this._componentType = BaseExtension.COMPONENT_TYPE_LIST;

    // Initialize
    this._init();
}

export default ListExtension;
