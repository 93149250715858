import BaseSaveComponent from 'js/base_v2/save-component';
/**
 * Base Toggle Save Button.
 *
 * @class
 * @abstract
 * @extends BaseSaveComponent
 *
 * @param {DOMElement} ct
 * @param {DOMElement} btn
 * @param {?object}    options
 */
function BaseToggleSaveButton(ct, btn, options) {
  BaseSaveComponent.call(this, ct, options);
  const parent = this.clone();
  const self = this;

  /**
     * @prop {DOMElement}
     */
  this.btn = btn;

  /**
     * @inheritDoc
     */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      fieldName: undefined,
      dataMap: {
        0: 0,
        1: 1,
      },
      html: {
        0: this.getFalseStateHtml(),
        1: this.getTrueStateHtml(),
      },
      onToggle: undefined,
    });
  };

  /**
     * @inheritDoc
     */
  this.processOptions = function() {
    parent.processOptions.call(this);
    return this.processFieldNameOption();
  };

  /**
     * Process field name option.
     *
     * @return {BaseToggleSaveButton}
     */
  this.processFieldNameOption = function() {
    if (!_.isUndefined(this.options.fieldName)) {
      // Nothing to process
      return this;
    }

    const fieldName = this.btn.data('name');

    if (!_.isString(fieldName) || _.isEmpty(fieldName)) {
      // Nothing to process
      return this;
    }

    return this.extendOptions({
      fieldName,
    });
  };

  /**
     * @inheritDoc
     */
  this.registerEventListeners = function() {
    this.btn.off('click');
    this.btn.on('click', (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      self.onBtnClick();
    });

    return this;
  };

  /**
     * Button click event handler.
     */
  this.onBtnClick = function() {
    this.toggleBtn();
  };

  /**
     * Toggle button.
     *
     * @return {BaseToggleSaveButton}
     */
  this.toggleBtn = function() {
    this.oldValue = this.btn.attr('data-value');
    const newValue = +!+this.oldValue;

    if (_.isFunction(this.options.onToggle) &&
            false === this.options.onToggle(this.options.fieldName, newValue)
    ) {
      return false;
    }

    this.btn.attr('data-value', newValue);

    return this.save();
  };

  /**
     * @inheritDoc
     */
  this.notifyError = function(error) {
    parent.notifyError.call(this, error);

    this.messageSpan.html('');
    this.btn.show();

    return this;
  };

  /**
     * @inheritDoc
     */
  this.notifyLoading = function() {
    parent.notifyLoading.call(this);
    this.btn.hide();

    return this;
  };

  /**
     * @inheritDoc
     */
  this.notifySuccess = function() {
    parent.notifySuccess.call(this);

    this.messageSpan.html('');
    this.btn.show();

    return this;
  };

  /**
     * @inheritDoc
     */
  this.setNewValue = function(record) {
    const invDataMap = _.invert(this.options.dataMap);
    const value = invDataMap[record[this.options.fieldName]];

    return this.renderBtn(value);
  };

  /**
     * @inheritDoc
     */
  this.setOldValue = function() {
    return this.renderBtn(this.oldValue);
  };

  /**
     * Render button.
     *
     * @param  {string}               value
     * @return {BaseToggleSaveButton}
     */
  this.renderBtn = function(value) {
    this.btn
      .attr('data-value', value)
      .html(this.options.html[value]);

    return this;
  };

  /**
     * @inheritDoc
     */
  this.getData = function() {
    const fieldData = Object.createFromKey(
      this.options.fieldName,
      this.options.dataMap[+this.btn.attr('data-value')],
    );

    return Object.createFromKey(
      this.options.entityName,
      fieldData,
    );
  };

  /**
     * Get "false" state HTML.
     *
     * @return {string}
     */
  this.getFalseStateHtml = function() {
    /* eslint-disable-next-line no-multi-str */
    return '\
      <label class="btn_toogle">\
        <input type="checkbox">\
        <span class="c-indicator"></span>\
      </label>\
    ';
  };

  /**
     * Get "true" state HTML.
     *
     * @return {string}
     */
  this.getTrueStateHtml = function() {
    /* eslint-disable-next-line no-multi-str */
    return '\
      <label class="btn_toogle">\
        <input type="checkbox" checked>\
        <span class="c-indicator"></span>\
      </label>\
    ';
  };
}

export default BaseToggleSaveButton;
