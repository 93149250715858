const PricingUtils = {};

/**
 * @param  {*}       value
 * @return {?number}
 */
PricingUtils.sanitize = function (value) {
  if (_.isUndefined(value) || _.isNull(value) || _.isEmpty(value)) {
    return value;
  }

  let sanitizedValue = value;

  if (_.isString(value)) {
    sanitizedValue = PricingUtils.removeThousandSeparator(value);
  }

  return parseFloat(sanitizedValue);
};

/**
 * @param  {string} value
 * @return {string}
 */
PricingUtils.removeThousandSeparator = function (value) {
  let amount = value;

  if (-1 !== amount.indexOf(',')) {
    amount = amount.replace(/,/g, '');
  }

  return amount;
};

export default PricingUtils;
