import BaseEntity from 'js/base_v3/models/entity';

const staticSelf = PartFieldCriteria;

/**
 * @const
 */
staticSelf.TYPE_PART = 'part';

/**
 * @const
 */
staticSelf.TYPE_CORPORATE_PART = 'corporate_part';

/**
 * Part Field Criteria.
 *
 * @class
 * @extends BaseEntity
 *
 * @param {object} [data]
 */
function PartFieldCriteria(data) {
  BaseEntity.call(this, data);

  // Initialize
  this._init();
}

export default PartFieldCriteria;
