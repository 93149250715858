import BaseToggleSaveButton from 'js/base_v2/toggle-save-button';

const staticSelf = ViewToggleButton;

staticSelf.VERSION_VIEW_TYPE_DEFAULT = 'partfiniti';
staticSelf.VERSION_VIEW_TYPE_REACT = 'partfiniti_v2';

/**
 * User - Version View Setting Toggle Button.
 *
 * @class
 * @extends BaseToggleSaveButton
 *
 * @param {DOMElement} ct
 * @param {DOMElement} btn
 * @param {object}     options
 */
function ViewToggleButton(ct, btn, options) {
  BaseToggleSaveButton.call(this, ct, btn, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function () {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      url: undefined,
      entityName: 'user_setting',
      saveUrl: '/user/my-setting-edit/save',
      fieldName: 'value',
      dataMap: {
        0: staticSelf.VERSION_VIEW_TYPE_DEFAULT,
        1: staticSelf.VERSION_VIEW_TYPE_REACT,
      },
      saveParams: {
        user_setting: {
          name: 'default_version_view_type',
        },
      },
      onSave: () => this.onVersionViewSave(),
    });
  };

  /**
   * @inheritDoc
   */
  this.notifyLoading = function () {
    this.renderBtn(1);
    return this;
  };

  this.onVersionViewSave = function () {
    window.location.href = this.options.url;
    return false;
  };

  // Initialize
  this.init();
}

export default ViewToggleButton;
