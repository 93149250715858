/**
 * Base Object.
 *
 * @class
 * @abstract
 *
 * @param {object} [options]
 */
function BaseObject(options) {
    const self = this;

    /**
     * @protected
     *
     * @prop {object}
     */
    this._defaultOptions = {};

    /**
     * @protected
     *
     * @prop {object}
     */
    this._options = _.isObject(options) ? options : {};

    /**
     * Set data attribute.
     *
     * @param  {string}     key
     * @param  {*}          value
     * @return {BaseObject}
     */
    this.setDataAttribute = function(key, value) {
        this[key] = value;

        return this;
    };

    /**
     * Get option.
     *
     * @param  {string} key
     * @return {*}
     */
    this.getOption = function(key) {
        return this._options[key];
    };

    /**
     * Set option.
     *
     * @param  {string}     key
     * @param  {*}          value
     * @return {BaseObject}
     */
    this.setOption = function(key, value) {
        this._options[key] = value;

        return this;
    };

    /**
     * Get options.
     *
     * @return {object}
     */
    this.getOptions = function() {
        return this._options;
    };

    /**
     * Extend options.
     *
     * @param  {object}     newOptions
     * @return {BaseObject}
     */
    this.extendOptions = function(newOptions) {
        this._options = this._options.extend(newOptions);

        return this;
    };

    /**
     * Initialize object.
     *
     * @protected
     *
     * @return {BaseObject}
     */
    this._init = function() {
        return this
            ._initDataAttributes()
            ._initEarlyProps()
            ._initDefaults()
            ._initOptions()
            ._initProps()
            ._processOptions();
    };

    /**
     * Initialize data attributes.
     *
     * @protected
     *
     * @return {BaseObject}
     */
    this._initDataAttributes = function() {
        this.extendOptions({
            data: {}
        });

        _.each(this._options.data, function(value, key) {
            if (null === this[key]) {
                this[key] = value;
            } else if (null === this['_' + key]) {
                this['_' + key] = value;
            }
        }, this);

        return this._processDataAttributes();
    };

    /**
     * Process data attributes.
     *
     * @protected
     *
     * @return {BaseObject}
     */
    this._processDataAttributes = function() {
        return this;
    };

    /**
     * Initialize early properties.
     *
     * @return {BaseObject}
     */
    this._initEarlyProps = function() {
        return this;
    };

    /**
     * Initialize defaults.
     *
     * @protected
     *
     * @return {BaseObject}
     */
    this._initDefaults = function() {
        return this;
    };

    /**
     * Initialize options.
     *
     * @protected
     *
     * @return {BaseObject}
     */
    this._initOptions = function() {
        this._options = this._defaultOptions.extend(this._options);

        return this;
    };

    /**
     * Initialize properties.
     *
     * @protected
     *
     * @return {BaseObject}
     */
    this._initProps = function() {
        return this;
    };

    /**
     * Process options.
     *
     * @protected
     *
     * @return {BaseObject}
     */
    this._processOptions = function() {
        return this;
    };

    /**
     * Extend default options.
     *
     * @protected
     *
     * @param  {object}     newDefaultOptions
     * @return {BaseObject}
     */
    this._extendDefaultOptions = function(newDefaultOptions) {
        this._defaultOptions = this._defaultOptions.extend(newDefaultOptions);

        return this;
    };
}

export default BaseObject;
