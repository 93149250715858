import BaseObject from 'js/base_v3/objects/object';

const staticSelf = BaseExtension;

/**
 * @const
 */
staticSelf.COMPONENT_TYPE_FIELD = 'field';

/**
 * @const
 */
staticSelf.COMPONENT_TYPE_LIST = 'list';

/**
 * Base Extension.
 *
 * @class
 * @abstract
 * @extends BaseObject
 *
 * @param {object}  parentComponent
 * @param {object}  [options]
 */
function BaseExtension(parentComponent, options) {
    BaseObject.call(this, options);
    const parent = this.clone();
    const self = this;

    /**
     * @protected
     *
     * @prop {string}
     */
    this._componentType = null;

    /**
     * @protected
     *
     * @prop {object}
     */
    this._parentComponent = parentComponent;

    /**
     * Create component.
     *
     * @param  {string}        name
     * @param  {DOMElement}    [wrapperContainer]
     * @param  {object}        [extraOptions]
     * @return {BaseComponent}
     */
    this.createComponent = function(name, wrapperContainer, extraOptions = {}) {
        const componentClass = this._getComponentClass(name);

        return new componentClass(
            this._getComponentContainer(name, wrapperContainer), // Component container
            this._getComponentElement(name, wrapperContainer),   // Component element
            this._getComponentOptions(name).extend(extraOptions) // Options
        ).create();
    };

    /**
     * @inheritDoc
     */
    this._init = function() {
        // Create alias for "createComponent", e.g. "createField", "createList", etc
        this['create' + this._componentType.snakeToCamel().capitalize()] = function(
            name,
            wrapperContainer,
            extraOptions = {}
        ) {
            return this.createComponent(name, wrapperContainer, extraOptions);
        };

        return parent._init.call(this);
    };

    /**
     * Get component.
     *
     * @protected
     *
     * @param  {string}        name
     * @return {BaseComponent}
     */
    this._getComponent = function(name) {
        return this._parentComponent[this._getComponentKey(name)];
    };

    /**
     * Get component class.
     *
     * @protected
     *
     * @param  {string}   name
     * @return {function}
     */
    this._getComponentClass = function(name) {
        return this._parentComponent.getOption(this._getComponentClassKey(name));
    };

    /**
     * Get component container.
     *
     * @protected
     *
     * @param  {string}     name
     * @param  {DOMElement} [wrapperContainer]
     * @return {DOMElement}
     */
    this._getComponentContainer = function(name, wrapperContainer) {
        return $(
            this._getComponentContainerSelector(name),
            wrapperContainer || this._parentComponent.getContainer()
        );
    };

    /**
     * Get component element.
     *
     * @protected
     *
     * @param  {string}     name
     * @param  {DOMElement} [wrapperContainer]
     * @return {DOMElement}
     */
    this._getComponentElement = function(name, wrapperContainer) {
        return $(
            this._getComponentSelector(name),
            wrapperContainer || this._parentComponent.getContainer()
        );
    };

    /**
     * Get component options.
     *
     * @protected
     *
     * @param  {string} name
     * @return {object}
     */
    this._getComponentOptions = function(name) {
        return this._parentComponent.getOptions()[this._getComponentOptionsKey(name)];
    };

    /**
     * Get key to be used to get component class from parent component
     * options.
     *
     * @protected
     *
     * @param  {string} name
     * @return {string}
     */
    this._getComponentClassKey = function(name) {
        return name +
            this._componentType.snakeToCamel().capitalize() +
            'Class';
    };

    /**
     * Get component key.
     *
     * @protected
     *
     * @param  {string} name
     * @return {string}
     */
    this._getComponentKey = function(name) {
        return name + this._componentType.snakeToCamel().capitalize();
    };

    /**
     * Get key to be used to get component options from parent component
     * options.
     *
     * @protected
     *
     * @param  {string} name
     * @return {string}
     */
    this._getComponentOptionsKey = function(name) {
        return name + this._componentType.snakeToCamel().capitalize();
    };

    /**
     * Get component container selector.
     *
     * @protected
     *
     * @param  {string} name
     * @return {string}
     */
    this._getComponentContainerSelector = function(name) {
        return this._getComponentSelector(name) + 'Container';
    };

    /**
     * Get component selector.
     *
     * @protected
     *
     * @param  {string} name
     * @return {string}
     */
    this._getComponentSelector = function(name) {
        return '.' + name + this._componentType.snakeToCamel().capitalize();
    };
}

export default BaseExtension;
