function BaseEntity(data) {
    var self = this;

    /**
     * @protected
     *
     * @prop {object}
     */
    this._data = {};

    /**
     * @protected
     *
     * @prop {string[]}
     */
    this._modifiedFields = [];

    /**
     * @protected
     *
     * @prop {object}
     */
    this._originalValues = {};

    /**
     * Get data.
     *
     * @return {object}
     */
    this.getData = function() {
        return this._data;
    };

    /**
     * Set data.
     *
     * @param  {object}     data
     * @return {BaseEntity}
     */
    this.setData = function(data) {
        this._data = this._data.extend(data);

        _.each(data, function(value, field) {
            this.set(field, value);
        }, this);

        return this;
    };

    /**
     * Set field value.
     *
     * @param  {string}     field
     * @param  {*}          value
     * @return {BaseEntity}
     */
    this.set = function(field, value) {
        this._setOriginalValue(field);

        this._data[field] = value;
        this[field] = value;
        this._modifiedFields.push(field);

        return this;
    };

    /**
     * Restore original values.
     *
     * @return {BaseEntity}
     */
    this.restore = function() {
        _.each(this._originalValues, function(value, field) {
            this._data[field] = value;
            this[field] = value;
        }, this);

        return this.clearModifiedFields();
    };

    /**
     * Get modified field values.
     *
     * @return {object}
     */
    this.getModifiedFieldValues = function() {
        var modifiedFieldValues = {};

        _.each(this._modifiedFields, function(modifiedField) {
            modifiedFieldValues[modifiedField] = this[modifiedField];
        }, this);

        return modifiedFieldValues;
    };

    /**
     * Clear modified fields.
     *
     * @return {BaseEntity}
     */
    this.clearModifiedFields = function() {
        this._modifiedFields = [];
        this._originalValues = {};

        return this;
    };

    /**
     * Initialize.
     *
     * @protected
     *
     * @return {BaseEntity}
     */
    this._init = function() {
        return this
            .setData(data)
            .clearModifiedFields();
    };

    /**
     * Set original values.
     *
     * @protected
     *
     * @param  {string}     field
     * @return {BaseEntity}
     */
    this._setOriginalValue = function(field) {
        if (!_.isUndefined(this._originalValues[field])) {
            return this;
        }

        this._originalValues[field] = this[field];

        return this;
    };
}

export default BaseEntity;
