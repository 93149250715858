var PartNumberHelper = {};

/**
 * Transform part number to value.
 *
 * @param  {?string} partNumber
 * @return {?string}
 */
PartNumberHelper.toValue = function(partNumber) {
    if (!_.isString(partNumber)) {
        return null;
    }

    return partNumber.replace(/ /g, '_');
};

/**
 * Transform part number to label.
 *
 * @param  {?string} partNumber
 * @return {?string}
 */
PartNumberHelper.toLabel = function(partNumber) {
    if (!_.isString(partNumber)) {
        return null;
    }

    return partNumber.replace(/_/g, ' ');
};

/**
 * Determine whether the part numbers are the same.
 *
 * @param  {string}  firstPartNumber
 * @param  {string}  secondPartNumber
 * @return {boolean}
 */
PartNumberHelper.areTheSame = function(firstPartNumber, secondPartNumber) {
    return PartNumberHelper.toValue(firstPartNumber) ===
        PartNumberHelper.toValue(secondPartNumber);
};

/**
 * Get part number from record.
 *
 * @param  {object}  record
 * @return {?string}
 */
PartNumberHelper.getPartNumberFromRecord = function(record) {
    return record.number || record.part;
};

export default PartNumberHelper;
