/* eslint-disable */

import BomElement from 'js/bom/models/element';
import Category from 'js/categories/models/category';
import PartFieldCriteria from 'js/category_v2/models/part-field-criteria';
import PriceHelper from 'js/helpers/price-helper';
import PartNumberHelper from 'js/part/helpers/number-helper'
import { partUrlHelper } from 'js/part/helpers/url-helper';
import { translator } from 'js/translator';
import PricingUtils from 'js/utils/pricing';

var Renderer = {};

/**
 * @const
 */
Renderer.DEFAULT_PRICING_DECIMALS = 5;

/**
 * @const
 */
Renderer.DEFAULT_SMALL_PRICING_DECIMALS = 5;

/**
 * @const
 */
Renderer.DEFAULT_BIG_PRICING_DECIMALS = 2;

/**
 * @const
 */
Renderer.DEFAULT_QUANTITY_DECIMALS = 2;

/**
 * @const
 */
Renderer.DEFAULT_SMALL_QUANTITY_DECIMALS = 5;

/**
 * Render address input name.
 *
 * @param  {string} baseEntity
 * @param  {string} addressEntity
 * @param  {string} addressField
 * @return {string}
 */
Renderer.AddressInputName = function(baseEntity, addressEntity, addressField) {
    if (baseEntity && addressEntity) {
        return baseEntity + '[' + addressEntity + '][' + addressField + ']';
    }

    if (addressEntity) {
        return addressEntity + '[' + addressField + ']'
    }

    return addressField;
};

/**
 * Render address input value.
 *
 * @param  {object} record
 * @param  {string} addressEntity
 * @param  {string} addressField
 * @return {string}
 */
Renderer.AddressInputValue = function(record, addressEntity, addressField) {
    var value = record[addressEntity] && record[addressEntity][addressField];

    if (!value) {
        value = record[addressEntity + '_' + addressField];
    }

    return value || '';
};

/**
 * Render big price.
 *
 * @param  {number}         amount
 * @param  {string|boolean} currency
 * @return {string}
 */
Renderer.BigPrice = function(amount, currency) {
    return Renderer.Price(amount, '0,0', currency);
};

/**
 * Render big price and GPM.
 *
 * @param  {number}         price
 * @param  {number}         cost
 * @param  {string|boolean} currency
 * @return {string}
 */
Renderer.BigPriceAndGpm = function(price, cost, currency) {
    var gpm = PriceHelper.computeGpm(cost, price);

    return '<span>' + Renderer.BigPrice(price, currency) + '</span>' +
        '<span class="percent">' + Renderer.Percentage(gpm) + '<span>';
};

/**
 * Render date and time.
 *
 * @param  {string} dateString
 * @return {string}
 */
Renderer.DateAndTime = function(dateString) {
    if (_.isEmpty(dateString)) {
        return '-';
    }

    var m = moment(dateString);
    return m.format('MM/DD/YY HH:mm');
};

/**
 * Render date in short format: MM/DD/YY.
 *
 * @param  {string} dateString
 * @param  {string} [placeholder]
 * @return {string}
 */
Renderer.Date = function(dateString, placeholder = '-') {
    if (_.isEmpty(dateString)) {
        return placeholder;
    }

    var m = moment(dateString);
    return m.format('MM/DD/YY');
};

/**
 * Render date in long format: MM/DD/YYYY.
 *
 * @param  {string} dateString
 * @param  {string} [placeholder]
 * @return {string}
 */
Renderer.DateLong = function(dateString, placeholder = '-') {
    if (_.isEmpty(dateString)) {
        return placeholder;
    }

    return moment(dateString).format('MM/DD/YYYY');
};

/**
 * Render date range.
 *
 * @param  {moment|string|null} startDate
 * @param  {moment|string|null} endDate
 * @param  {object}             options
 * @return {string}
 */
Renderer.DateRange = function(startDate, endDate, opts = {}) {
    const options = {
        labelNoDates: translator.getTitle('label_no_dates'),
        labelSince: translator.getTitle('label_since'),
        labelUntil: translator.getTitle('label_until'),
        format: 'MM/DD/YYYY',
    }.extend(opts);

    startDate = startDate instanceof moment ? startDate : moment(startDate);
    endDate = endDate instanceof moment ? endDate : moment(endDate);

    const startDateEmpty = !startDate.isValid();
    const endDateEmpty = !endDate.isValid();

    if (startDateEmpty && endDateEmpty) {
        return options.labelNoDates;
    }

    if (startDateEmpty) {
        return `${options.labelUntil} ${endDate.format(options.format)}`;
    }

    if (endDateEmpty) {
        return `${options.labelSince} ${startDate.format(options.format)}`;
    }

    return `${startDate.format(options.format)} - ${endDate.format(options.format)}`;
};

/**
 * Render month and year.
 *
 * @param {string} dateString
 */
Renderer.MonthAndYear = function(dateString) {
    if (_.isEmpty(dateString)) {
        return '-';
    }

    var m = moment(dateString);
    return m.format('MM/YYYY');
};

/**
 * Render time.
 *
 * @param  {string} timeString
 * @return {string}
 */
Renderer.Time = function(timeString) {
    if (_.isEmpty(timeString)) {
        return '-';
    }

    var m = moment(timeString);
    return m.format('HH:mm');
};

/**
 * Convert value to label.
 *
 * @param  {string} value
 * @param  {object} [options]
 * @return {string}
 */
Renderer.valueToLabel = function(value, options = {}) {
    options = {
        capitalizeWords: true,
        placeholder: ''
    }.extend(options);

    value = (_.isString(value) && !_.isEmpty(value)) ?
        value :
        options.placeholder;

    let label = value.replace(/_/g, ' ');

    if (options.capitalizeWords) {
        label = label.capitalizeWords();
    }

    return label;
};

/**
 * Render address.
 *
 * @param  {object} address
 * @param  {string} lineSeparator
 * @param  {string} type
 * @return {string}
 */
Renderer.Address = function(address, lineSeparator, type, options = {}) {
    var options = {
        showContactName: false,
        editable: false,
        showAccountsReceivableId: false,
    }.extend(options);

    lineSeparator = lineSeparator || '<br>';

    var name = address.name || '';

    if (options.showContactName && _.isString(address.contact_name)) {
        name = address.contact_name
    }

    var line1 = address.line1 || '';
    var line2 = address.line2 || '';
    var city = address.city || '';
    var state = address.state || '';
    var country = address.country || '';
    var zip = address.zip || '';
    var accountsReceivableId = address.accounts_receivable_id || '';

    accountsReceivableLine = '';
    if (options.showAccountsReceivableId && accountsReceivableId) {
        accountsReceivableLine = translator.getTitle('label_address_accounts_receivable_id') + accountsReceivableId;
    }

    if (type === 'short') {
        var lines = [
            $.trim(name),
            $.trim(line1 + ' ' + line2),
            $.trim(city + (city ? ', ' : '') + ' ' + state + ' ' + zip)
        ];
    } else {
        var lines = [
            '<span class="address-title">' + name + '</span>',
            $.trim(line1 + ' ' + line2),
            $.trim(city + (city ? ', ' : '') + ' ' + state),
            $.trim(country + ' ' + zip),
            $.trim(accountsReceivableLine)
        ];
    }

    lines = lines.filter(function(value) { return value; });
    var html = lines.join(lineSeparator);

    if (options.editable) {
        pencilIcon = '<svg class="icon-small"><use xlink:href="#icon-pencil"></use></svg>';

        html = pencilIcon + html;
    }

    return html;
};

/**
 * Render location and city.
 *
 * @param  {?object} address
 * @return {string}
 */
Renderer.LocationAndCity = function(address, placeholder) {
    placeholder = !_.isUndefined(placeholder) ? placeholder : '';

    if (!_.isObject(address)) {
        return placeholder;
    }

    var pieces = [address.name, address.city].filter(function(str) {
        return str;
    });

    return pieces.join(', ') || '-';
};

/**
 * Render city and state.
 *
 * @param  {object} address
 * @return {string}
 */
Renderer.CityAndState = function(address) {
    var pieces = [address.city, address.state].filter(function(str) {
        return str !== translator.get('not_available_label') && str !== null;
    });

    return pieces.join(', ') || '-';
};

/**
 * Render count.
 *
 * @param  {number} count
 * @return {string}
 */
Renderer.Count = function(count) {
    if (!(count > 0)) {
        return '-';
    }

    return Renderer.Format(count, '0,0');
};

/**
 * Render date in date picker format 'MM/DD/YY'.
 *
 * @param  {string} dateString
 * @return {string}
 */
Renderer.DatePickerFormat = function(dateString) {
    if (!dateString) {
        return '';
    }

    return moment(dateString).format('MM/DD/YY');
};

/**
 * Render interval of dates.
 *
 * @param  {string} sinceDateString
 * @param  {string} untilDateString
 * @param  {string} format
 * @return {string}
 */
Renderer.DateInterval = function(
  sinceDateString,
  untilDateString,
  format = 'D/M/YY'
) {
    const options = {
      label_no_dates: translator.getTitle('label_no_dates'),
      label_since: translator.getTitle('label_since'),
      label_until: translator.getTitle('label_until'),
    };

    var since = sinceDateString ? moment(sinceDateString) : null;
    var until = untilDateString ? moment(untilDateString) : null;

    if (!since && !until) {
        return options.label_no_dates;
    }

    if (!since) {
        return `${options.label_until} ${until.format(format)}`;;
    }

    if (!until) {
        return `${options.label_since} ${since.format(format)}`;;
    }

    return `${since.format(format)} - ${until.format(format)}`;
};

/**
 * Render element tooltip.
 *
 * @param  {object} element
 * @param  {object} [record]
 * @param  {string} [className]
 * @return {string}
 */
Renderer.ElementTooltip = function(element, record, className) {
    var quantity = element.quantity *
        (_.isObject(record) ? record.quantities_per_element[element.id] : 1);

    var label = '';

    if (BomElement.ID_SHORTAGE === element.id) {
        label = '-';
    } else if (BomElement.ID_OVERAGE === element.id) {
        label = '+';
    } else {
        label = 0 == element.empty ? element.line : 'X';
    }

    var description = element.name +
        '<br>Quantity: ' + Renderer.Quantity(quantity);

    if (_.isObject(record) &&
        _.isObject(record.element_component_notes_map) &&
        _.isArray(record.element_component_notes_map[element.id])
    ) {
      _.each(record.element_component_notes_map[element.id], (note, index) => {
        description += `
          <div class="note-container">
            <span>
              ${0 === index ? `<span class="note-type-label">${translator.getTitle('label_element_note')}:</span>` : ''}
              ${note.content}
            </span>
          </div>
        `;
      });
    }

    return '<div class="wrap_tooltip element-tooltip-ct ' + (_.isString(className) ? className : '') + '">' +
        '<span class="icon_tooltip">' + label + '</span>' +
        '<span class="desc_tooltip">' + description + '</span>' +
        '</div>';
};

/**
 * Format amount.
 *
 * @param  {number} amount
 * @param  {string} format
 * @return {string}
 */
Renderer.Format = function(amount, format) {
    return numeral(amount).format(format);
};

/**
 * Render text ellipsis.
 *
 * @param  {number}   maxLength
 * @return {function}
 */
Renderer.Long = function(maxLength)
{
    return function(x, cls){
        if(!x || !x.split)
            return '';

        var words = x.split(' ');

        var shortStr = '';
        var longStr = '';

        var length = 0;

        for (var i = 0; i < words.length; i++) {
            if(length + words[i].length > maxLength){
                longStr = words.slice(i).join(' ').trim();
                break;
            } else {
                length += words[i].length;
                shortStr += ' ' + words[i];
            }
        }

        var result = '<span class="longshort' + (cls ? (' ' + cls) : '') + '">' + shortStr.trim();

        if(longStr.length > 0){
            result += '<a href=# class="doshow stopPropagation" tabindex="-1">... <svg><use xlink:href="#icon-plus-square-o"></use></svg></a>' +
                ' <span class="long hidden">' + longStr + '</span>' +
                ' <span class="full hidden">' + x + '</span>' +
                ' <a href="#" class="dohide stopPropagation hidden" tabindex="-1"><svg><use xlink:href="#icon-minus-square-o"></use></svg></a>';
        }

        result += '</span>';

        return  result;
    }
};

/**
 * Render text ellipsis.
 *
 * @param  {string}  text
 * @param  {number}  maxHeight
 * @param  {?string} containerClass
 * @return {string}
 */
Renderer.TextEllipsis = function(text, maxHeight, containerClass) {
    containerClass = _.isUndefined(containerClass) ? '' : containerClass;

    return '\
         <div class="textEllipsisCt text-ellipsis-container details_collapsed details_over ' + containerClass + '" style="max-height: ' + maxHeight + 'px;">\
            <span class="textEllipsisContent stopPropagation">' + text + '</span>\
            <a href="#" class="stopPropagation textEllipsisButton text-ellipsis-btn">\
                <svg class="showOverflowText icon-plus"><use xlink:href="#icon-plus"></use></svg>\
                <svg class="hideOverflowText icon-minus"><use xlink:href="#icon-minus"></use></svg>\
            </a>\
         </div>'
    ;
};

/**
 * Render discount / fixed price label.
 *
 * @param  {object} record
 * @param  {string} type
 * @return {string}
 */
Renderer.DiscountLabel = function(record, type) {
    type = type || 'discount';

    switch (type) {
        case 'discount':
        case 'margin':
            return Renderer.PercentageDiscountLabel(record);
        case 'fixed_price':
            return Renderer.FixedPriceLabel(record);
        default:
            return '';
    }
};

/**
 * Render percentage discount label.
 *
 * @param  {object} discount
 * @return {string}
 */
Renderer.PercentageDiscountLabel = function(discount) {
    switch (discount.type) {
        case 'base':
            return Renderer.BaseDiscountLabel();
        case 'base_margin':
            return Renderer.BaseMarginLabel();
        case 'manufacturer':
            return Renderer.ManufacturerDiscountLabel(discount);
        case 'category':
            return Renderer.CategoryDiscountLabel(discount);
        case 'manufacturer_category':
            return Renderer.ManufacturerCategoryDiscountLabel(discount);
        case 'part_field':
            return Renderer.PartFieldDiscountLabel(discount);
        default:
            return '';
    }
};

/**
 * Render base discount label.
 *
 * @return {string}
 */
Renderer.BaseDiscountLabel = function() {
    return 'Base Discount';
};

/**
 * Render base margin label.
 *
 * @return {string}
 */
Renderer.BaseMarginLabel = function() {
    return 'Base Margin';
};

/**
 * Render manufacturer discount label.
 *
 * @param  {object} discount
 * @return {string}
 */
Renderer.ManufacturerDiscountLabel = function(discount) {
    return discount.manufacturer_name;
};

/**
 * Render category discount label.
 *
 * @param  {object} discount
 * @return {string}
 */
Renderer.CategoryDiscountLabel = function(discount) {
    return discount.category_path || 'All';
};

/**
 * Render manufacturer and category discount label.
 *
 * @param  {object} discount
 * @return {string}
 */
Renderer.ManufacturerCategoryDiscountLabel = function(discount) {
    return Renderer.ManufacturerDiscountLabel(discount) + ': ' +
        Renderer.CategoryDiscountLabel(discount);
};

/**
 * Render part field discount label.
 *
 * @param  {object} discount
 * @return {string}
 */
Renderer.PartFieldDiscountLabel = function(discount) {
    var manufacturerCategoryHtml = '';

    var manufacturerEmpty = !(discount.manufacturer_id > 0);

    var categoryEmpty = !(discount.category_id > 0) ||
        Category.ROOT_ID == discount.category_id;

    if (!manufacturerEmpty && !categoryEmpty) {
        manufacturerCategoryHtml += Renderer.ManufacturerCategoryDiscountLabel(discount);
    } else if (!manufacturerEmpty) {
        manufacturerCategoryHtml += Renderer.ManufacturerDiscountLabel(discount);
    } else if (!categoryEmpty) {
        manufacturerCategoryHtml += Renderer.CategoryDiscountLabel(discount);
    }

    var partFieldHtml = Renderer.PartFieldCriteria(discount);

    return '<div class="manufacturer-category-display-container">' + manufacturerCategoryHtml + '</div>' +
        (manufacturerCategoryHtml.length > 0 ? '' : '<i>' + translator.get('part_field_criteria_label') + '</i>') +
        '<div class="part-field-criteria-display-container">' + partFieldHtml + '</div>';
};

/**
 * Render part field criteria.
 *
 * @param  {object} record
 * @param  {string} type
 * @return {string}
 */
Renderer.PartFieldCriteria = function(
    record,
    type = PartFieldCriteria.TYPE_PART
) {
    var partFieldHtml = '';

    const criteriaArr = PartFieldCriteria.TYPE_CORPORATE_PART === type ?
        record.corporate_part_field_criteria :
        record.part_field_criteria;

    _.each(criteriaArr, function(partFieldCriterion) {
        let partFieldName = 'corporate_part' === type ?
            translator.get('label_corporate_part_field_'+ partFieldCriterion.corporate_part_field_name) :
            $.trim(partFieldCriterion.part_field_group + ' ' + partFieldCriterion.part_field_name);

        const operatorLabel = Renderer.valueToLabel(
          partFieldCriterion.operator,
          {capitalizeWords: false}
        );

        partFieldHtml += '<div>' +
            ' <b>' + partFieldName + '</b>' +
            ' <i>' + operatorLabel + '</i>' +
            ' <b>' + Renderer.valueToLabel(partFieldCriterion.value) + '</b>';

        if (partFieldCriterion.uom_id) {
            partFieldHtml += ' ' + partFieldCriterion.uom_name;
        }

        partFieldHtml += '</div>';
    }, this);

    return partFieldHtml;
};

/**
 * Render fixed price label.
 *
 * @param  {object} record
 * @return {string}
 */
Renderer.FixedPriceLabel = function(record) {
    return Renderer.PartNumber(record.part);
};

/**
 * Render discount value.
 *
 * @param  {number} value
 * @param  {string} type
 * @param  {object} opts
 * @return {string}
 */
Renderer.DiscountValue = function(value, type, opts = {}) {
    type = type || 'discount';
    var prefix = '';

    const options = {
        displayZero: false
    }.extend(opts);

    switch (type) {
        case 'cost_discount':
        case 'discount':
            prefix = '-';
            break;
        case 'margin':
            prefix = '+';
            break;
    }

    if (_.isNull(value) || (!options.displayZero && 0 === value)) {
        return '-';
    }

    return prefix + numeral(value / 100).format('0,0.000%');
};

/**
 * Render HTML message.
 *
 * @param  {string} type         ['loading', 'error']
 * @param  {string} html
 * @param  {string} iconPosition ['left', 'right']
 * @param  {string} iconClass
 * @return {string}
 */
Renderer.Message = function(type, html, iconPosition, iconClass) {
    html = html || '';
    iconPosition = iconPosition || 'right';
    iconClass = iconClass || '';
    iconClass += html ? (' icon-' + iconPosition) : '';

    var iconHtml = '';

    switch (type) {
        case 'back':
            iconHtml = '<i class="icon ' + iconClass + ' fa fa-arrow-left" aria-hidden="true"></i>';
            break;
        case 'ban':
            iconHtml = '<i class="icon ' + iconClass + ' fa fa-ban" aria-hidden="true"></i>';
            break;
        case 'cancel':
            iconHtml = '<i class="icon ' + iconClass + ' fa fa-times" aria-hidden="true"></i>';
            break;
        case 'create':
            iconHtml = '<i class="icon ' + iconClass + ' fa fa-plus" aria-hidden="true"></i>';
            break;
        case 'error':
            iconHtml = '<i class="icon ' + iconClass + ' fa fa-exclamation-circle" aria-hidden="true"></i>';
            break;
        case 'info':
            iconHtml = '<i class="icon ' + iconClass + ' fa fa-info-circle" aria-hidden="true"></i>';
            break;
        case 'loading':
            iconHtml = '<i class="icon ' + iconClass + ' fa fa-spinner fa-pulse fa-3x fa-fw" aria-hidden="true"></i>';
            break;
        case 'select':
            iconHtml = '<i class="' + iconClass + ' fa fa-hand-pointer-o" aria-hidden="true"></i></i>';
            break;
        case 'success':
            iconHtml = '<i class="icon ' + iconClass + ' fa fa-check-circle" aria-hidden="true"></i>';
            break;
        case 'warning':
            iconHtml = '<i class="icon ' + iconClass + ' fa fa-exclamation-circle" aria-hidden="true"></i>';
            break;
    }

    switch (iconPosition) {
        case 'left':
            html = iconHtml + html;
            break;
        case 'right':
            html = html + iconHtml;
            break;
    }

    return html;
};

/**
 * Render back HTML.
 *
 * @param  {string} html
 * @param  {string} iconPosition ['left', 'right']
 * @return {string}
 */
Renderer.Back = function(html, iconPosition) {
    return Renderer.Message('back', html, iconPosition);
};

/**
 * Render ban HTML.
 *
 * @param  {string} html
 * @param  {string} iconPosition ['left', 'right']
 * @return {string}
 */
Renderer.Ban = function(html, iconPosition) {
    return Renderer.Message('ban', html, iconPosition);
};

/**
 * Render cancel HTML.
 *
 * @param  {string} html
 * @param  {string} iconPosition ['left', 'right']
 * @return {string}
 */
Renderer.Cancel = function(html, iconPosition) {
    return Renderer.Message('cancel', html, iconPosition);
};

/**
 * Render create HTML.
 *
 * @param  {string} html
 * @param  {string} iconPosition ['left', 'right']
 * @return {string}
 */
Renderer.Create = function(html, iconPosition) {
    return Renderer.Message('create', html, iconPosition);
};

/**
 * Render email address.
 *
 * @param  {?string} email
 * @param  {string}  [placeholder]
 * @return {string}
 */
Renderer.Email = function(email, placeholder) {
    placeholder = !_.isUndefined(placeholder) ? placeholder : '';

    if (_.isEmpty(email)) {
        return placeholder;
    }

    return '<a href="mailto:' + email + '">' + email + '</a>';
};

/**
 * Render error HTML.
 *
 * @param  {string} html
 * @param  {string} iconPosition ['left', 'right']
 * @return {string}
 */
Renderer.Error = function(html, iconPosition, iconClass) {
    return Renderer.Message('error', html, iconPosition, iconClass);
};

/**
 * Render days.
 *
 * @param  {number} amount
 * @return {string}
 */
Renderer.Days = function(amount) {
    if (!(amount > 0)) {
        return '-';
    }

    var label = 1 == amount ? 'day' : 'days';

    return numeral(amount).format('0,0') + ' ' + label;
};

/**
 * Render info HTML.
 *
 * @param  {string} html
 * @param  {string} iconPosition ['left', 'right']
 * @return {string}
 */
Renderer.Info = function(html, iconPosition) {
    return Renderer.Message('info', html, iconPosition);
};

/**
 * Render number.
 *
 * @param  {*}      value
 * @param  {string} [placeholder]
 * @return {string}
 */
Renderer.Number = function(value, placeholder) {
    placeholder = !_.isUndefined(placeholder) ? placeholder : '-';

    if (isNaN(value)) {
        return placeholder;
    }

    return '' + numeral(value).value();
};

/**
 * Render integer.
 *
 * @param  {number} value
 * @param  {object} [opts]
 * @return {string}
 */
Renderer.Int = function(value, opts = {}) {
  const options = {
    allowZero: true
  }.extend(opts);

  if (!value || (!options.allowZero && 0 == value)) {
    return '-';
  }

  return numeral(value).format('0,0');
};

/**
 * Render loading HTML.
 *
 * @param  {string} html
 * @param  {string} iconPosition ['left', 'right']
 * @param  {string} iconClass
 * @return {string}
 */
Renderer.Loading = function(html, iconPosition, iconClass) {
    return Renderer.Message('loading', html, iconPosition, iconClass);
};

/**
 * Render part number.
 *
 * @param  {?string} partNumber
 * @return {string}
 */
Renderer.PartNumber = function(partNumber) {
    return PartNumberHelper.toLabel(partNumber) || '';
};

/**
 * Render part label.
 *
 * @param  {object}  part
 * @param  {object} [options]
 * @return {string}
 */
Renderer.PartLabel = function(part, options) {
    // Apply default options
    options = {
        display_manufacturer: false
    }.extend(options);

    const partNumber = part.number || part.part || 'N/A';

    let label = Renderer.PartNumber(partNumber);

    if (options.display_manufacturer && part.manufacturer_id > 0) {
        label += ' | ' + part.manufacturer_name;
    }

    return label;
};

/**
 * Render part link.
 *
 * @param  {?object} part
 * @param  {object}  [opts]
 * @return {string}
 */
Renderer.PartLink = function(part, opts = {}) {
    const options = {
        placeholder: '-',
        attributes: undefined,
    }.extend(opts);

    if (!_.isObject(part)) {
        return options.placeholder;
    }

    part.number = part.number || part.part;

    let attributes = '';

    if (_.isObject(options.attributes)) {
        _.each(options.attributes, function(value, name) {
            attributes += (attributes.length > 0 ? ' ' : '') +
                name + '="' + value + '"';
        }, this);
    }

    return '<a href="' + Renderer.PartUrl(part) + '" ' + attributes + '>' +
        Renderer.PartNumber(part.number) +
        '</a>';
};

/**
 * Render part URL.
 *
 * @deprecated
 *
 * @param  {object} part
 * @return {string}
 */
Renderer.PartUrl = function(part) {
    return partUrlHelper.getUrl(part);
};

/**
 * Render percentage value using "numeral" format.
 *
 * @param  {number} value
 * @param  {string} [format]
 * @param  {string} [placeholder]
 * @return {string}
 */
Renderer.Percentage = function(value, format, placeholder) {
    format = format || '0,0.00%';
    placeholder = _.isString(placeholder) ? placeholder : '-';

    if (null === value || !$.isNumeric(value)) {
        return placeholder;
    }

    return numeral(value).format(format);
};

/**
 * Render percentage range.
 *
 * @param  {number} minValue
 * @param  {number} maxValue
 * @param  {string} [format]
 * @return {string}
 */
Renderer.PercentageRange = function(
    minValue,
    maxValue,
    format = '0,0.00%'
) {
    const options = {
        label_min: translator.getTitle('label_minimum_short'),
        label_max: translator.getTitle('label_maximum_short'),
    };

    const minValueEmpty = Number.isNaN(parseFloat(minValue));
    const maxValueEmpty = Number.isNaN(parseFloat(maxValue));

    if (minValueEmpty && maxValueEmpty) {
        return '-';
    }

    const minimum = Renderer.Percentage(minValue, format);
    const maximum = Renderer.Percentage(maxValue, format);

    if (minValueEmpty) {
        return `${options.label_max}: ${maximum}`;
    }

    if (maxValueEmpty) {
        return `${options.label_min}: ${minimum}`;
    }

    return `${minimum} - ${maximum}`;
};

/**
 * Render percentage value.
 *
 * @param  {number} value
 * @param  {string} format
 * @return {string}
 */
Renderer.PercentageValue = function(value, format) {
    if (null === value || !$.isNumeric(value)) {
        return '';
    }

    format = format || '0.00';

    return numeral(100 * value).format(format);
};

/**
 * Render price amount using "numeral" format.
 *
 * @param  {*}              amount
 * @param  {string}         [format]
 * @param  {string|boolean} [currency]
 * @param  {boolean}        [thousandSeparator]
 * @param  {number}         [decimals]
 * @param  {string}         [placeholder]
 * @param  {number}         [smallDecimals]
 * @return {string}
 */
Renderer.Price = function(
    amount,
    format,
    currency,
    thousandSeparator,
    decimals,
    placeholder,
    smallDecimals = Renderer.DEFAULT_SMALL_PRICING_DECIMALS,
    bigDecimals = Renderer.DEFAULT_BIG_PRICING_DECIMALS,
) {
    placeholder = _.isString(placeholder) ? placeholder : '-';

    amount = PricingUtils.sanitize(amount);

    if (_.isUndefined(amount) ||
        _.isNull(amount) ||
        _.isNaN(amount * 1)
    ) {
        return placeholder;
    }

    thousandSeparator = (!_.isUndefined(thousandSeparator) && !_.isNull(thousandSeparator)) ?
        thousandSeparator :
        true;

    if (_.isUndefined(decimals) || _.isNull(decimals)) {
        decimals = parseInt(
            amount >= 10 ? bigDecimals : Renderer.DEFAULT_PRICING_DECIMALS
        );
    }

    if (amount < 1) {
        decimals = smallDecimals;
    }

    const decimalFormat = Array(Math.min(decimals, 2) + 1).join('0') +
        (decimals > 2 ? ('[' + Array(decimals - 1).join('0') + ']') : '');

    if (thousandSeparator) {
        format = format || `0,0.${decimalFormat}`;
    } else {
        format = format || `0.${decimalFormat}`;
    }

    if (false === currency) {
        currency = '';
    } else if (!currency) {
        currency = '$';
    }

    if (currency) {
        format = currency + format;
    }

    return numeral(amount).format(format);
};

/**
 * Render raw price (value only).
 *
 * @param  {*}      amount
 * @param  {number} [decimals]
 * @return {string}
 */
Renderer.RawPrice = function(amount, decimals, smallDecimals = Renderer.DEFAULT_SMALL_PRICING_DECIMALS) {
    return Renderer.Price(
        amount, // Amount
        undefined, // Format
        false, // Currency
        false, // Thousand separator
        decimals, // Decimals
        '', // Placeholder
        smallDecimals, // Small amount decimals
    );
};

/**
 * Render price amount per uom.
 *
 * @param  {number}         amount
 * @param  {?string}        uom
 * @param  {?number}        uomQuantity
 * @param  {string}         format
 * @param  {string|boolean} currency
 * @param  {number}         decimals
 * @return {string}
 */
Renderer.PricePerUom = function(amount, uom, uomQuantity, format, currency, decimals) {
    if (!(amount > 0)) {
        return '-';
    }

    return Renderer._joinContentPieces([
        Renderer.Price(amount, format, currency, true, decimals),
        Renderer.PerUom(uom, uomQuantity)
    ]);
};

/**
 * Render per UOM (e.g. "/1000 feet", "/each").
 *
 * @param  {?string} uom
 * @param  {?number} [uomQuantity]
 * @return {string}
 */
Renderer.PerUom = function(uom, uomQuantity) {
    if (!_.isString(uom) || 0 === uom.length) {
        return '';
    }

    return '/' + Renderer._joinContentPieces([
        Renderer.UomQuantity(uomQuantity),
        uom
    ]);
};

/**
 * Render UOM quantity.
 *
 * @param  {?number} [uomQuantity]
 * @return {string}
 */
Renderer.UomQuantity = function(uomQuantity) {
    uomQuantity = uomQuantity > 0 ? uomQuantity : 1;

    if (1 == uomQuantity) {
        return '';
    }

    return numeral(uomQuantity).format('0');
};

/**
 * Render phone number.
 *
 * @param  {?string} phone
 * @param  {string}  [placeholder]
 * @return {string}
 */
Renderer.Phone = function(phone, placeholder) {
    placeholder = !_.isUndefined(placeholder) ? placeholder : '';

    if (_.isEmpty(phone)) {
        return placeholder;
    }

    return '<a href="tel:' + phone + '">' + phone + '</a>';
};

/**
 * Render quantity using "numeral" format.
 *
 * @param  {number}  amount
 * @param  {string}  format
 * @param  {boolean} allowZeroAmount
 * @param  {boolean} thousandSeparator
 * @param  {number}  decimals
 * @param  {string}  [placeholder]
 * @return {string}
 */
Renderer.Quantity = function(
    amount,
    format,
    allowZeroAmount,
    thousandSeparator,
    decimals,
    placeholder,
    smallDecimals = Renderer.DEFAULT_SMALL_QUANTITY_DECIMALS
) {
    placeholder = !_.isUndefined(placeholder) ? placeholder : '-';

    if (_.isUndefined(amount) || _.isNull(amount) ||
        (0 == amount && !allowZeroAmount)
    ) {
        return placeholder;
    }

    thousandSeparator = !_.isUndefined(thousandSeparator) ?
        thousandSeparator : true;

    decimals = (!_.isUndefined(decimals) && !_.isNull(decimals)) ?
        parseInt(decimals) :
        Renderer.DEFAULT_QUANTITY_DECIMALS;

    if (amount < 1) {
        decimals = smallDecimals;
    }

    if (thousandSeparator) {
        format = format || ('0,0.' + '[' + Array(decimals + 1).join('0') + ']');
    } else {
        format = format || ('0.' + '[' + Array(decimals + 1).join('0') + ']');
    }

    return numeral(amount).format(format);
};

/**
 * Render inventory quantity.
 *
 * @param  {number}  amount
 * @return {string}
 */
Renderer.InventoryQuantity = function(amount) {
    return Renderer.Quantity(
        amount,    // Amount
        undefined, // Format
        true       // Allow Zero Amount
    );
};

/**
 * Render raw quantity (value only).
 *
 * @param  {number} amount
 * @param  {number} decimals
 * @return {string}
 */
Renderer.RawQuantity = function(amount, decimals) {
    return Renderer.Quantity(amount, undefined, true, false, decimals);
};

/**
 * Render quantity and UOM.
 *
 * @param  {number}  amount
 * @param  {string}  uom
 * @param  {string}  format
 * @param  {boolean} allowZeroAmount
 * @param  {boolean} thousandSeparator
 * @param  {number}  decimals
 * @return {string}
 */
Renderer.QuantityAndUom = function(
    amount,
    uom,
    format,
    allowZeroAmount,
    thousandSeparator,
    decimals
) {
    var uomStr = !_.isUndefined(uom) && !_.isNull(uom) ? ' ' + uom : '';
    var quantityStr = Renderer.Quantity(
        amount,
        format,
        allowZeroAmount,
        thousandSeparator,
        decimals
    );

    return quantityStr + uomStr;
};

/**
 * Render quantity per measure using "numeral" format.
 *
 * @param  {number} amount
 * @param  {string} format
 * @return {string}
 */
Renderer.QuantityPerMeasure = function(amount, format) {
    if (_.isUndefined(amount) || _.isNull(amount) || 0 == amount) {
        return '-';
    }

    format = format || '0,0';

    return numeral(amount).format(format);
};

/**
 * Render select HTML.
 *
 * @param  {string} html
 * @param  {string} iconPosition ['left', 'right']
 * @return {string}
 */
Renderer.Select = function(html, iconPosition) {
    return Renderer.Message('select', html, iconPosition);
};

/**
 * Render success HTML.
 *
 * @param  {string} html
 * @param  {string} iconPosition ['left', 'right']
 * @return {string}
 */
Renderer.Success = function(html, iconPosition) {
    return Renderer.Message('success', html, iconPosition);
};

/**
 * Format link
 *
 * @param {string} url
 * @return {string}
 */
Renderer.Link = function(url)
{
    url = $.trim(url);

    if (url.length === 0) {
        return '';
    }

    _.each(['http://', 'https://'], function(prefix) {
        if (url.substr(0, prefix.length) === prefix) {
            url = url.substr(prefix.length);
        }
    });

    if (url.length === 0) {
        return '';
    }

    if (url[url.length-1] === '/') {
        url = url.substr(0, url.length - 1);
    }

    return url;
};


/**
 * Render category path label.
 *
 * @param  {string} categoryNamesStr
 * @return {string}
 */
Renderer.CategoryPathLabel = function(categoryNamesStr) {
    let path = categoryNamesStr.replaceAll(',', ' > ');

    if (path.length > 35) {
      return `...${path.slice(-35)}`;
    }

    return path;
}

/**
 * Render quantity/UoM threshold rules label.
 *
 * @param  {string} jsonString
 * @param  {object} options
 * @return {string}
 */
Renderer.QuantityUomThreshold = function(jsonString, options = {}) {
    options = {
        demarcator: '-',
    }.extend(options);

    if (_.isEmpty(jsonString)) {
        return '-';
    }

    const uomThresholds = JSON.parse(jsonString);

    if (_.isEmpty(uomThresholds)) {
        return '-';
    }

    let html = '';

    _.each(uomThresholds, (quantity, uom) => {
        html += `<p>${quantity}${options['demarcator']}${uom}</p>`;
    });

    return html;
}

/**
 * Render add button.
 *
 * @param  {object} [options]
 * @return {string}
 */
Renderer.AddButton = function(options = {}) {
    options = {
        buttonClass: '',
    }.extend(options);

    return `
      <a href="#" class="${options.buttonClass} stopPropagation">
        <svg><use xlink:href="#icon-add"></use></svg>
      </a>
    `;
}

/**
 * Filter content pieces.
 *
 * @param  {string[]} contentPieces
 * @return {string[]}
 */
Renderer._filterContentPieces = function(contentPieces) {
    return contentPieces.filter(function(contentPiece) {
        return _.isString(contentPiece) && contentPiece.length > 0;
    });
};

/**
 * Render duration.
 *
 * @param  {*}      duration
 * @param  {object} [opts]
 * @return {string}
 */
Renderer.Duration = function(duration, opts = {}) {
    const options = {
        ...opts,
        decimals: 2,
        placeholder: '-',
        thousandSeparator: true,
    };

    if (!$.isNumeric(duration)) {
        return options.placeholder;
    }

    return numeral(duration).format(Renderer._getNumeralFormat(options));
};

/**
 * Join content pieces.
 *
 * @param {string[]} contentPieces
 * @param {?string}  glue
 * @param {?boolean} filter
 */
Renderer._joinContentPieces = function(contentPieces, glue, filter) {
    glue = _.isString(glue) ? glue : ' ';
    filter = _.isBoolean(filter) ? filter : true;

    if (filter) {
        contentPieces = Renderer._filterContentPieces(contentPieces);
    }

    return contentPieces.join(glue);
};

/**
 * @param  {object} options
 * @return {string}
 */
Renderer._getNumeralFormat = function(options) {
    return options.thousandSeparator ?
        ('0,0.' + '[' + Array(options.decimals + 1).join('0') + ']') :
        ('0.' + '[' + Array(decimals + 1).join('0') + ']');
};

export default Renderer;
