const NumberHelper = {};

/**
 * Convert to float.
 *
 * @param  {string} numAsString
 * @return {number}
 */
NumberHelper.toFloat = function(numAsString) {
  let num;

  try {
    num = parseFloat(numAsString);
  } catch (e) {
    num = null;
  }

  return num;
};

/**
 * Check whether the first value is equal to the second one considering given
 * precision.
 *
 * @param  {number}  firstValue
 * @param  {number}  secondValue
 * @param  {number}  precision
 * @return {boolean}
 */
NumberHelper.areEqual = function(firstValue, secondValue, precision) {
  return Math.abs(firstValue - secondValue) < precision;
};

/**
 * Compare first value with second one considering given precision.
 *
 * @param  {number}  first
 * @param  {number}  second
 * @param  {number}  precision
 * @return {?number}
 */
NumberHelper.compare = function(first, second, precision) {
  const firstValue = NumberHelper.toFloat(first);
  const secondValue = NumberHelper.toFloat(second);

  if (_.isNaN(firstValue) || _.isNaN(secondValue)) {
    return null;
  }

  if (!_.isNumber(firstValue) || !_.isNumber(secondValue)) {
    return null;
  }

  if (NumberHelper.areEqual(firstValue, secondValue, precision)) {
    return 0;
  }

  return firstValue < secondValue ? -1 : 1;
};

/**
 * Round value to precision.
 *
 * @param  {number} value
 * @param  {number} precision
 * @return {number}
 */
NumberHelper.round = function(value, precision) {
  return parseFloat(value.toFixed(precision));
};

export default NumberHelper;
