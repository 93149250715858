const Ajax = {};

/**
 * AJAX request.
 *
 * @param  {string}         type
 * @param  {string}         url
 * @param  {object}         data
 * @param  {function}       successCallback
 * @param  {function}       errorCallback
 * @param  {object}         [opts]
 * @return {XMLHttpRequest}
 */
Ajax.request = function(
  type,
  url,
  data,
  successCallback,
  errorCallback,
  opts = {},
) {
  const options = {
    dataType: 'json',
    messageCt: null,
    ...opts,
  };

  const { messageCt } = options;

  return $.ajax({
    type,
    url,
    data,
    dataType: options.dataType,
    success(response) {
      if ('json' === options.dataType && !response.success) {
        if ('function' === typeof errorCallback) {
          errorCallback(
            response,
            Ajax.getErrorMessage(response),
            messageCt,
          );
        }

        return;
      }

      if ('function' === typeof successCallback) {
        successCallback(response, messageCt);
      }
    },
    error(jqXHR) {
      if ('function' === typeof errorCallback) {
        errorCallback(
          jqXHR.responseJSON,
          Ajax.getErrorMessage(jqXHR.responseJSON || {}),
          messageCt,
        );
      }
    },
  });
};

/**
 * AJAX GET request.
 *
 * @param  {string}         url
 * @param  {object}         data
 * @param  {function}       successCallback
 * @param  {function}       errorCallback
 * @param  {object}         options
 * @return {XMLHttpRequest}
 */
Ajax.get = function(url, data, successCallback, errorCallback, options) {
  return Ajax.request(
    'GET',
    url,
    data,
    successCallback,
    errorCallback,
    options,
  );
};

/**
 * AJAX POST request.
 *
 * @param  {string}         url
 * @param  {object}         data
 * @param  {function}       successCallback
 * @param  {function}       errorCallback
 * @param  {object}         options
 * @return {XMLHttpRequest}
 */
Ajax.post = function(url, data, successCallback, errorCallback, options) {
  return Ajax.request(
    'POST',
    url,
    data,
    successCallback,
    errorCallback,
    options,
  );
};

/**
 * AJAX default error callback.
 *
 * @protected
 *
 * @param  {object} response
 * @return {string}
 */
Ajax.getErrorMessage = function(response) {
  let errorMessage = _.isString(response.error) ?
    response.error :
    'Server error.';

  const { errors } = response;

  if (_.isArray(errors) && !_.isEmpty(errors)) {
    errorMessage += ` See the error breakdown below:<br>${
      errors.map((error) => error.message).join('<br>')}`;
  }

  return errorMessage;
};

export default Ajax;
