import BaseObject from 'js/base_v2/object';
import Translator from 'js/translator';

/**
 * Base Component.
 *
 * @class
 * @abstract
 * @extends BaseObject
 *
 * @param {object} [options]
 */
function BaseComponent(options) {
    BaseObject.call(this, options);
    const parent = this.clone();
    const self = this;

    /**
     * @prop {Translator}
     */
    this.translator = null;

    /**
     * @inheritDoc
     */
    this.init = function() {
        this.translator = new Translator();

        return parent.init.call(this);
    };

    /**
     * Create component.
     *
     * @return {BaseComponent}
     */
    this.create = function() {
        return this.createSubcomponents();
    };

    /**
     * Recreate component.
     *
     * @param  {number}        destroyDelay
     * @return {BaseComponent}
     */
    this.recreate = function(destroyDelay) {
        return this
            .destroy(destroyDelay)
            .create();
    }

    /**
     * Destroy component.
     *
     * @param  {number}        delay
     * @return {BaseComponent}
     */
    this.destroy = function(delay) {
        delay = _.isNumber(delay) ? delay : 0;

        if (0 === delay) {
            this.destroyNow();

            return this;
        }

        setTimeout(function() {
            self.destroyNow();
        }, delay);

        return this;
    };

    /**
     * Destroy component now.
     *
     * @return {BaseComponent}
     */
    this.destroyNow = function() {
        return this;
    };

    /**
     * Create subcomponents.
     *
     * @return {BaseComponent}
     */
    this.createSubcomponents = function() {
        return this;
    };
}

export default BaseComponent;
