import BaseEntity from 'js/base_v3/models/entity';
var staticSelf = Category;

/**
 * @const
 */
staticSelf.ROOT_ID = 7;

/**
 * Category Entity.
 *
 * @class
 * @extends BaseEntity
 *
 * @param {?object} data
 */
function Category(data) {
    BaseEntity.call(this, data);
    var parent = this.clone();
    var self = this;

    // Initialize
    this._init();
}

export default Category;
